var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as Styled from './Input.styles';
var Input = forwardRef(function (_a, ref) {
    var icon = _a.icon, label = _a.label, _b = _a.status, status = _b === void 0 ? 'default' : _b, message = _a.message, props = __rest(_a, ["icon", "label", "status", "message"]);
    var hasMessage = message !== undefined && message !== null;
    return (_jsxs(Styled.Label, __assign({ hasMessage: hasMessage, hasLabel: !!label }, { children: [label && _jsx(Styled.LabelText, { children: label }, void 0), icon && _jsx(Styled.Icon, { children: icon }, void 0), _jsx(Styled.Input, __assign({ icon: icon, ref: ref }, props), void 0), hasMessage && _jsx(Styled.Message, __assign({ status: status }, { children: message }), void 0)] }), void 0));
});
export default Input;
