export var Z_INDEX = {
    MODAL_OVERLAY: 152,
    DRAWER_CONTENT: 151,
    DRAWER_DIMMER: 150,
    ASIDE: 121,
    HEADER: 120,
    RESERVATION_BUTTON: 103,
    TIME_PICKER_OPTIONS_CONTAINER: 102,
    TIME_PICKER_CONTAINER: 101,
    TIME_PICKER_DIMMER: 100,
    SELECT_LIST_BOX_BUTTON: 50,
    SELECT_LIST_BOX: 49,
    SPACE_ADD_BUTTON: 51,
};
export var FORM_MAX_WIDTH = '660px';
