var _a;
var GITHUB_OAUTH_KEY = (function () {
    var _a, _b, _c;
    if (process.env.NODE_ENV === 'development' && process.env.DEPLOY_ENV === 'development') {
        return (_a = process.env.GITHUB_OAUTH_KEY_LOCAL) !== null && _a !== void 0 ? _a : '';
    }
    if (process.env.NODE_ENV === 'production' && process.env.DEPLOY_ENV === 'development') {
        return (_b = process.env.GITHUB_OAUTH_KEY_DEV) !== null && _b !== void 0 ? _b : '';
    }
    if (process.env.NODE_ENV === 'production' && process.env.DEPLOY_ENV === 'production') {
        return (_c = process.env.GITHUB_OAUTH_KEY_PROD) !== null && _c !== void 0 ? _c : '';
    }
    return '';
})();
var GOOGLE_OAUTH_KEY = (_a = process.env.GOOGLE_OAUTH_KEY) !== null && _a !== void 0 ? _a : '';
var REDIRECT_URI = "" + (process.env.NODE_ENV === 'production' ? 'https://' : 'http://') + window.location.hostname + (window.location.port ? ":" + window.location.port : '');
var PATH = {
    MAIN: '/',
    LOGIN: '/login',
    MANAGER_JOIN: '/join',
    MANAGER_SOCIAL_JOIN: '/join/social',
    MANAGER_GITHUB_OAUTH_REDIRECT: '/login/oauth/github',
    MANAGER_GOOGLE_OAUTH_REDIRECT: '/login/oauth/google',
    MANAGER_PROFILE_EDIT: '/profile/edit',
    MANAGER_PASSWORD_EDIT: '/password/edit',
    MANAGER_MAP_DETAIL: '/map/:mapId',
    MANAGER_MAP_LIST: '/map/list',
    MANAGER_MAP_CREATE: '/map/create',
    MANAGER_MAP_EDIT: '/map/:mapId/edit',
    MANAGER_SPACE_EDIT: '/map/:mapId/space/edit',
    MANAGER_RESERVATION: '/reservation',
    MANAGER_RESERVATION_EDIT: '/reservation/edit',
    GUEST_MAIN: '/guest',
    GUEST_MAP: '/guest/:sharingMapId',
    GUEST_RESERVATION: '/guest/:sharingMapId/reservation',
    GUEST_RESERVATION_EDIT: '/guest/:sharingMapId/reservation/edit',
    GUEST_RESERVATION_SUCCESS: '/guest/:sharingMapId/success',
    GUEST_NON_LOGIN_RESERVATION_SEARCH: '/guest/non-login/reservation/search',
    NOT_FOUND: '/not-found',
    GITHUB_LOGIN: "https://github.com/login/oauth/authorize?client_id=" + GITHUB_OAUTH_KEY + "&redirect_uri=" + REDIRECT_URI + "/login/oauth/github",
    GOOGLE_LOGIN: 'https://accounts.google.com/o/oauth2/v2/auth?' +
        'scope=https://www.googleapis.com/auth/userinfo.email&' +
        'access_type=offline&' +
        'include_granted_scopes=true&' +
        'response_type=code&' +
        'state=state_parameter_passthrough_value&' +
        ("redirect_uri=" + REDIRECT_URI + "/login/oauth/google&") +
        ("client_id=" + GOOGLE_OAUTH_KEY),
};
export var HREF = {
    MANAGER_MAP_DETAIL: function (mapId) {
        return PATH.MANAGER_MAP_DETAIL.replace(':mapId', "" + mapId);
    },
    MANAGER_MAP_EDIT: function (mapId) { return PATH.MANAGER_MAP_EDIT.replace(':mapId', "" + mapId); },
    MANAGER_SPACE_EDIT: function (mapId) {
        return PATH.MANAGER_SPACE_EDIT.replace(':mapId', "" + mapId);
    },
    GUEST_MAP: function (sharingMapId) {
        return PATH.GUEST_MAP.replace(':sharingMapId', "" + sharingMapId);
    },
    GUEST_RESERVATION: function (sharingMapId) {
        return PATH.GUEST_RESERVATION.replace(':sharingMapId', "" + sharingMapId);
    },
    GUEST_RESERVATION_EDIT: function (sharingMapId) {
        return PATH.GUEST_RESERVATION_EDIT.replace(':sharingMapId', "" + sharingMapId);
    },
    GUEST_RESERVATION_SUCCESS: function (sharingMapId) {
        return PATH.GUEST_RESERVATION_SUCCESS.replace(':sharingMapId', "" + sharingMapId);
    },
};
export default PATH;
