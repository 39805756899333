import dayjs, { isDayjs } from 'dayjs';
import { Midday } from 'types/time';
// Note: YYYY-MM-DD 형식으로 변환함
export var formatDate = function (value) {
    if (isDayjs(value)) {
        return value.format('YYYY-MM-DD');
    }
    var year = value.getFullYear();
    var month = ("" + (value.getMonth() + 1)).padStart(2, '0');
    var date = ("" + value.getDate()).padStart(2, '0');
    return year + "-" + month + "-" + date;
};
var DAY = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
// Note: YYYY/MM/DD (MON) 형식으로 변환함
export var formatDateWithDay = function (value) {
    if (isDayjs(value)) {
        return value.format('YYYY-MM-DD (ddd)').toUpperCase();
    }
    var year = value.getFullYear();
    var month = ("" + (value.getMonth() + 1)).padStart(2, '0');
    var date = ("" + value.getDate()).padStart(2, '0');
    var day = value.getDay();
    return year + "/" + month + "/" + date + " (" + DAY[day] + ")";
};
// Note: HH:MM (24시간 기준) 형태로 변환함
export var formatTime = function (time) {
    if (isDayjs(time)) {
        return time.format('HH:mm');
    }
    if (time instanceof Date) {
        var hour_1 = time.getHours();
        var minute_1 = time.getMinutes();
        return (hour_1 < 10 ? "0" + hour_1 : "" + hour_1) + ":" + (minute_1 < 10 ? "0" + minute_1 : "" + minute_1);
    }
    var minute = ("" + time.minute).padStart(2, '0');
    if (time.hour === 12) {
        return (time.midday === Midday.AM ? '00' : '12') + ":" + minute;
    }
    var hour = time.midday === Midday.AM ? ("" + time.hour).padStart(2, '0') : "" + (time.hour + 12);
    return hour + ":" + minute;
};
// Note: HH:MM:SS (24시간 기준) 형태로 변환함
export var formatTimeWithSecond = function (time) {
    if (isDayjs(time)) {
        return time.format('HH:mm:ss');
    }
    if (time instanceof Date) {
        var hour_2 = time.getHours();
        var minute_2 = time.getMinutes();
        var second_1 = ("" + time.getSeconds()).padStart(2, '0');
        return (hour_2 < 10 ? "0" + hour_2 : "" + hour_2) + ":" + (minute_2 < 10 ? "0" + minute_2 : "" + minute_2) + ":" + second_1;
    }
    var minute = ("" + time.minute).padStart(2, '0');
    var second = '00';
    if (time.hour === 12) {
        return (time.midday === Midday.AM ? '00' : '12') + ":" + minute + ":" + second;
    }
    var hour = time.midday === Midday.AM ? ("" + time.hour).padStart(2, '0') : "" + (time.hour + 12);
    return hour + ":" + minute + ":" + second;
};
// Note: hh시간 mm분 형태로 변환함
export var formatTimePrettier = function (minutes) {
    var hour = Math.floor(minutes / 60);
    var minute = minutes % 60;
    return "" + (hour ? hour + "\uC2DC\uAC04" : '') + (minute ? ' ' : '') + (minute ? minute + "\uBD84" : '');
};
export var isPastTime = function (time, baseDate) {
    if (baseDate === void 0) { baseDate = new Date(); }
    if (isDayjs(time)) {
        return +time < +dayjs(baseDate).tz();
    }
    return time.getTime() < baseDate.getTime();
};
export var isPastDate = function (time, baseDate) {
    if (baseDate === void 0) { baseDate = new Date(); }
    if (isDayjs(time)) {
        return +time < +dayjs(baseDate).tz() - 1000 * 60 * 60 * 24;
    }
    return time.getTime() < baseDate.getTime() - 1000 * 60 * 60 * 24;
};
export var isFutureDate = function (time, baseDate) {
    if (baseDate === void 0) { baseDate = new Date(); }
    if (isDayjs(time)) {
        return +time > +dayjs(baseDate).tz() + 1000 * 60 * 60 * 24;
    }
    return time.getTime() > baseDate.getTime() + 1000 * 60 * 60 * 24;
};
// YYYY:MM:DD:HH:mm:ss -> N
export var convertTimeToMinutes = function (time) {
    return time.getHours() * 60 + time.getMinutes();
};
// HH:mm:ss -> N
export var convertSettingTimeToMinutes = function (time) {
    var _a = time.split(':'), hours = _a[0], minutes = _a[1];
    return Number(hours) * 60 + Number(minutes);
};
