import { ReservationStatus } from 'types/common';
export var getReservationStatus = function (startDateTime, endDateTime) {
    var now = new Date().getTime();
    var start = new Date(startDateTime).getTime();
    var end = new Date(endDateTime).getTime();
    if (start <= now && end >= now)
        return ReservationStatus.using;
    if (now > end)
        return ReservationStatus.done;
};
