import THROW_ERROR from 'constants/throwError';
import api from './api';
export var queryGuestReservations = function (_a) {
    var queryKey = _a.queryKey;
    var data = queryKey[1];
    var mapId = data.mapId, spaceId = data.spaceId, date = data.date;
    if (!mapId) {
        throw new Error(THROW_ERROR.INVALID_MAP_ID);
    }
    return api.get("/guests/maps/" + mapId + "/spaces/" + spaceId + "/reservations?date=" + date);
};
// 비회원 예약 조회
export var queryGuestNonLoginReservations = function (_a) {
    var userName = _a.userName, searchStartTime = _a.searchStartTime, page = _a.page;
    return api.get("/guests/non-login/reservations?userName=" + userName + "&searchStartTime=" + searchStartTime + "&page=" + page);
};
// 내 예약 조회
export var queryMemberReservations = function (_a) {
    var page = _a.page;
    return api.get("/guests/reservations?page=" + page);
};
// 내 이전 예약 조회
export var queryMemberReservationHistory = function (_a) {
    var page = _a.page;
    return api.get("/guests/reservations/history?page=" + page);
};
export var postGuestReservation = function (_a) {
    var reservation = _a.reservation, mapId = _a.mapId, spaceId = _a.spaceId;
    return api.post("/guests/maps/" + mapId + "/spaces/" + spaceId + "/reservations", reservation);
};
export var putGuestReservation = function (_a) {
    var reservation = _a.reservation, mapId = _a.mapId, spaceId = _a.spaceId, reservationId = _a.reservationId;
    return api.put("/guests/maps/" + mapId + "/spaces/" + spaceId + "/reservations/" + reservationId, reservation);
};
export var deleteGuestReservation = function (_a) {
    var mapId = _a.mapId, spaceId = _a.spaceId, reservationId = _a.reservationId, password = _a.password;
    return api.delete("/guests/maps/" + mapId + "/spaces/" + spaceId + "/reservations/" + reservationId, {
        data: { password: password },
    });
};
export var deleteMemberReservation = function (_a) {
    var mapId = _a.mapId, spaceId = _a.spaceId, reservationId = _a.reservationId;
    return api.delete("/guests/maps/" + mapId + "/spaces/" + spaceId + "/reservations/" + reservationId, {
        data: { password: null },
    });
};
export var postMemberGuestReservation = function (_a) {
    var reservation = _a.reservation, mapId = _a.mapId, spaceId = _a.spaceId;
    return api.post("/guests/maps/" + mapId + "/spaces/" + spaceId + "/reservations", reservation);
};
export var putMemberGuestReservation = function (_a) {
    var reservation = _a.reservation, mapId = _a.mapId, spaceId = _a.spaceId, reservationId = _a.reservationId;
    return api.put("/guests/maps/" + mapId + "/spaces/" + spaceId + "/reservations/" + reservationId, reservation);
};
