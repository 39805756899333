var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as Styled from './Switch.styled';
var Switch = function (_a) {
    var labelList = _a.labelList, selectedLabel = _a.selectedLabel, onClick = _a.onClick;
    return (_jsx(Styled.Container, { children: _jsx(Styled.ButtonContainer, { children: labelList.map(function (label) { return (_jsx(Styled.SwitchButton, __assign({ selected: label === selectedLabel, onClick: function () { return onClick(label); } }, { children: label }), label)); }) }, void 0) }, void 0));
};
export default Switch;
