export var getPolygonCenterPoint = function (coordinates) {
    var x = coordinates.map(function (p) { return p.x; });
    var y = coordinates.map(function (p) { return p.y; });
    var maxX = Math.max.apply(Math, x);
    var maxY = Math.max.apply(Math, y);
    var minX = Math.min.apply(Math, x);
    var minY = Math.min.apply(Math, y);
    return {
        x: (minX + maxX) / 2,
        y: (minY + maxY) / 2,
    };
};
