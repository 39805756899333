var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { createBrowserHistory } from 'history';
import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import PrivateRoute from 'PrivateRoute';
import Header from 'components/Header/Header';
import PATH from 'constants/path';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'constants/routes';
import AccessTokenProvider from 'providers/AccessTokenProvider';
import { GlobalStyle, theme } from './App.styles';
import NotFound from './pages/NotFound/NotFound';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Seoul');
export var history = createBrowserHistory();
export var queryClient = new QueryClient();
var App = function () {
    return (_jsxs(QueryClientProvider, __assign({ client: queryClient }, { children: [_jsx(AccessTokenProvider, { children: _jsxs(ThemeProvider, __assign({ theme: theme }, { children: [_jsx(GlobalStyle, {}, void 0), _jsx(Router, __assign({ history: history }, { children: _jsx(Suspense, __assign({ fallback: _jsx(Header, {}, void 0) }, { children: _jsxs(Switch, { children: [PUBLIC_ROUTES.map(function (_a) {
                                            var path = _a.path, component = _a.component;
                                            return (_jsx(Route, __assign({ exact: true, path: path }, { children: component }), path));
                                        }), PRIVATE_ROUTES.map(function (_a) {
                                            var path = _a.path, component = _a.component, redirectPath = _a.redirectPath;
                                            return (_jsx(PrivateRoute, __assign({ exact: true, path: path, redirectPath: redirectPath }, { children: component }), path));
                                        }), _jsx(Route, { path: ['*', PATH.NOT_FOUND], component: NotFound }, void 0)] }, void 0) }), void 0) }), void 0)] }), void 0) }, void 0), _jsx(ReactQueryDevtools, { initialIsOpen: false }, void 0)] }), void 0));
};
export default App;
