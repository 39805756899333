var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 220px;\n  padding-top: 60px;\n  background-color: ", ";\n"], ["\n  height: 220px;\n  padding-top: 60px;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.primary[50];
});
export var TabContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n"])));
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 300px;\n  background-color: ", ";\n  padding: 64px 96px;\n"], ["\n  height: 300px;\n  background-color: ", ";\n  padding: 64px 96px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.white;
});
var templateObject_1, templateObject_2, templateObject_3;
