import THROW_ERROR from 'constants/throwError';
import api from './api';
export var queryValidateEmail = function (_a) {
    var queryKey = _a.queryKey;
    var email = queryKey[1];
    if (typeof email !== 'string')
        throw new Error(THROW_ERROR.INVALID_EMAIL_FORMAT);
    return api.post("/members/validations/email", { email: email });
};
export var queryValidateUserName = function (_a) {
    var queryKey = _a.queryKey;
    var userName = queryKey[1];
    if (typeof userName !== 'string')
        throw new Error(THROW_ERROR.INVALID_USER_NAME_FORMAT);
    return api.post("/members/validations/username", { userName: userName });
};
export var postJoin = function (params) {
    return api.post('/members', params);
};
export var postSocialJoin = function (params) {
    return api.post("/members/oauth", params);
};
export var queryEmojiList = function () {
    return api.get('/members/emojis');
};
