var RESERVATION = {
    NAME: {
        MAX_LENGTH: 20,
    },
    DESCRIPTION: {
        MAX_LENGTH: 100,
    },
    PASSWORD: {
        MIN_LENGTH: 4,
        MAX_LENGTH: 4,
    },
};
export default RESERVATION;
