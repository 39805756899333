var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var variantCSS = {
    primary: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    color: ", ";\n    border: none;\n\n    &:disabled {\n      background: ", ";\n    }\n  "], ["\n    background: ", ";\n    color: ", ";\n    border: none;\n\n    &:disabled {\n      background: ", ";\n    }\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.primary[400];
    }, function (_a) {
        var theme = _a.theme;
        return theme.white;
    }, function (_a) {
        var theme = _a.theme;
        return theme.primary[100];
    }),
    inverse: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n    border: 1px solid ", ";\n    background: none;\n\n    &:disabled {\n      color: ", ";\n      border: 1px solid ", ";\n    }\n  "], ["\n    color: ", ";\n    border: 1px solid ", ";\n    background: none;\n\n    &:disabled {\n      color: ", ";\n      border: 1px solid ", ";\n    }\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.primary[400];
    }, function (_a) {
        var theme = _a.theme;
        return theme.primary[400];
    }, function (_a) {
        var theme = _a.theme;
        return theme.primary[100];
    }, function (_a) {
        var theme = _a.theme;
        return theme.primary[100];
    }),
    'primary-text': css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background: none;\n    border: none;\n    color: ", ";\n  "], ["\n    background: none;\n    border: none;\n    color: ", ";\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.primary[400];
    }),
    text: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background: none;\n    border: none;\n  "], ["\n    background: none;\n    border: none;\n  "]))),
    default: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    background: ", ";\n    border: 1px solid ", ";\n\n    &:disabled {\n      color: ", ";\n      border: 1px solid ", ";\n    }\n  "], ["\n    background: ", ";\n    border: 1px solid ", ";\n\n    &:disabled {\n      color: ", ";\n      border: 1px solid ", ";\n    }\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.white;
    }, function (_a) {
        var theme = _a.theme;
        return theme.black[400];
    }, function (_a) {
        var theme = _a.theme;
        return theme.gray[400];
    }, function (_a) {
        var theme = _a.theme;
        return theme.gray[400];
    }),
};
var shapeCSS = {
    default: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    border-radius: 0.125rem;\n  "], ["\n    border-radius: 0.125rem;\n  "]))),
    round: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    border-radius: 3rem;\n  "], ["\n    border-radius: 3rem;\n  "]))),
};
var sizeCSS = {
    dense: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    padding: 0 0.5rem;\n  "], ["\n    padding: 0 0.5rem;\n  "]))),
    small: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    padding: 0.25rem 0.5rem;\n  "], ["\n    padding: 0.25rem 0.5rem;\n  "]))),
    medium: css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    font-size: 1rem;\n    padding: 0.625rem 0.875rem;\n  "], ["\n    font-size: 1rem;\n    padding: 0.625rem 0.875rem;\n  "]))),
    large: css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    padding: 0.75rem 1rem;\n    font-size: 1.25rem;\n    font-weight: 700;\n  "], ["\n    padding: 0.75rem 1rem;\n    font-size: 1.25rem;\n    font-weight: 700;\n  "]))),
};
export var Button = styled.button(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", "\n  ", "\n  ", "\n  width: ", ";\n  cursor: ", ";\n"], ["\n  ", "\n  ", "\n  ", "\n  width: ", ";\n  cursor: ", ";\n"])), function (_a) {
    var variant = _a.variant;
    return variantCSS[variant];
}, function (_a) {
    var shape = _a.shape;
    return shapeCSS[shape];
}, function (_a) {
    var size = _a.size;
    return sizeCSS[size];
}, function (_a) {
    var fullWidth = _a.fullWidth;
    return (fullWidth ? '100%' : 'auto');
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'default' : 'pointer');
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
