var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import * as Styled from './MemberReservationListItem.styles';
var MemberReservationListItem = function (_a) {
    var reservation = _a.reservation, control = _a.control, onClick = _a.onClick;
    return (_jsxs(Styled.Container, __assign({ role: "listitem" }, { children: [_jsxs(Styled.InfoContainer, __assign({ onClick: onClick }, { children: [_jsx(Styled.Description, { children: reservation.description }, void 0), _jsxs(Styled.DetailContainer, { children: [_jsxs(Styled.Date, { children: [dayjs(new Date(reservation.startDateTime))
                                        .format('YYYY년 MM월 DD일 (ddd) HH:mm')
                                        .toUpperCase(), ' ', "- ", dayjs(new Date(reservation.endDateTime)).format('HH:mm')] }, void 0), _jsxs(Styled.Space, __assign({ spaceColor: reservation.spaceColor }, { children: [reservation.spaceName, " \u00B7 ", reservation.mapName] }), void 0)] }, void 0)] }), void 0), control && _jsx(Styled.ControlWrapper, { children: control }, void 0)] }), void 0));
};
export default MemberReservationListItem;
