import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import GuestMain from 'pages/GuestMain/GuestMain';
import GuestMapContainer from 'pages/GuestMap/GuestMapContainer';
import ManagerMapList from 'pages/ManagerMapList/ManagerMapList';
import ManagerPasswordEdit from 'pages/ManagerPasswordEdit/ManagerPasswordEdit';
import ManagerProfileEdit from 'pages/ManagerProfileEdit/ManagerProfileEdit';
import PATH from './path';
var GuestMap = React.lazy(function () { return import('pages/GuestMap/GuestMap'); });
var GuestReservation = React.lazy(function () { return import('pages/GuestReservation/GuestReservation'); });
var GuestReservationSuccess = React.lazy(function () { return import('pages/GuestReservation/GuestReservationSuccess'); });
var Main = React.lazy(function () { return import('pages/Main/Main'); });
var ManagerJoin = React.lazy(function () { return import('pages/ManagerJoin/ManagerJoin'); });
var ManagerSocialJoin = React.lazy(function () { return import('pages/ManagerSocialJoin/ManagerSocialJoin'); });
var Login = React.lazy(function () { return import('pages/Login/Login'); });
var ManagerMapDetail = React.lazy(function () { return import('pages/ManagerMapDetail/ManagerMapDetail'); });
var ManagerMapEditor = React.lazy(function () { return import('pages/ManagerMapEditor/ManagerMapEditor'); });
var ManagerReservation = React.lazy(function () { return import('pages/ManagerReservation/ManagerReservation'); });
var ManagerSpaceEditor = React.lazy(function () { return import('pages/ManagerSpaceEditor/ManagerSpaceEditor'); });
var GithubOAuthRedirect = React.lazy(function () { return import('pages/OAuthRedirect/GithubOAuthRedirect'); });
var GoogleOAuthRedirect = React.lazy(function () { return import('pages/OAuthRedirect/GoogleOAuthRedirect'); });
var GuestNonLoginReservationSearch = React.lazy(function () { return import('pages/GuestNonLoginReservationSearch/GuestNonLoginReservationSearch'); });
export var PUBLIC_ROUTES = [
    {
        path: PATH.MAIN,
        component: _jsx(Main, {}, void 0),
    },
    {
        path: PATH.LOGIN,
        component: _jsx(Login, {}, void 0),
    },
    {
        path: PATH.MANAGER_JOIN,
        component: _jsx(ManagerJoin, {}, void 0),
    },
    {
        path: PATH.MANAGER_SOCIAL_JOIN,
        component: _jsx(ManagerSocialJoin, {}, void 0),
    },
    {
        path: PATH.MANAGER_GITHUB_OAUTH_REDIRECT,
        component: _jsx(GithubOAuthRedirect, {}, void 0),
    },
    {
        path: PATH.MANAGER_GOOGLE_OAUTH_REDIRECT,
        component: _jsx(GoogleOAuthRedirect, {}, void 0),
    },
    {
        path: PATH.GUEST_MAP,
        component: _jsx(GuestMapContainer, {}, void 0),
    },
    {
        path: PATH.GUEST_RESERVATION,
        component: _jsx(GuestReservation, {}, void 0),
    },
    {
        path: PATH.GUEST_RESERVATION_EDIT,
        component: _jsx(GuestReservation, {}, void 0),
    },
    {
        path: PATH.GUEST_RESERVATION_SUCCESS,
        component: _jsx(GuestReservationSuccess, {}, void 0),
    },
    {
        path: PATH.GUEST_NON_LOGIN_RESERVATION_SEARCH,
        component: _jsx(GuestNonLoginReservationSearch, {}, void 0),
    },
];
export var PRIVATE_ROUTES = [
    {
        path: PATH.MANAGER_MAP_LIST,
        component: _jsx(ManagerMapList, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
    {
        path: PATH.MANAGER_PROFILE_EDIT,
        component: _jsx(ManagerProfileEdit, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
    {
        path: PATH.MANAGER_PASSWORD_EDIT,
        component: _jsx(ManagerPasswordEdit, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
    {
        path: PATH.MANAGER_RESERVATION,
        component: _jsx(ManagerReservation, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
    {
        path: PATH.MANAGER_RESERVATION_EDIT,
        component: _jsx(ManagerReservation, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
    {
        path: PATH.MANAGER_MAP_CREATE,
        component: _jsx(ManagerMapEditor, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
    {
        path: PATH.MANAGER_MAP_EDIT,
        component: _jsx(ManagerMapEditor, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
    {
        path: PATH.MANAGER_SPACE_EDIT,
        component: _jsx(ManagerSpaceEditor, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
    {
        path: PATH.GUEST_MAIN,
        component: _jsx(GuestMain, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
    {
        path: PATH.MANAGER_MAP_DETAIL,
        component: _jsx(ManagerMapDetail, {}, void 0),
        redirectPath: PATH.LOGIN,
    },
];
