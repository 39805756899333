import api from './api';
export var queryMember = function () {
    return api.get('/members/me');
};
export var putMember = function (_a) {
    var userName = _a.userName, emoji = _a.emoji;
    return api.put('/members/me', {
        userName: userName,
        emoji: emoji,
    });
};
export var putMemberPassword = function (params) {
    return api.put('/members/me/password', params);
};
