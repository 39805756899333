import api from './api';
export var queryManagerMaps = function () {
    return api.get('/managers/maps');
};
export var queryManagerMap = function (_a) {
    var queryKey = _a.queryKey;
    var data = queryKey[1];
    var mapId = data.mapId;
    return api.get("/managers/maps/" + mapId);
};
export var postMap = function (_a) {
    var mapName = _a.mapName, mapDrawing = _a.mapDrawing, thumbnail = _a.thumbnail;
    return api.post('/managers/maps', { mapName: mapName, mapDrawing: mapDrawing, thumbnail: thumbnail });
};
export var putMap = function (_a) {
    var mapId = _a.mapId, mapName = _a.mapName, mapDrawing = _a.mapDrawing, thumbnail = _a.thumbnail;
    return api.put("/managers/maps/" + mapId, { mapName: mapName, mapDrawing: mapDrawing, thumbnail: thumbnail });
};
export var deleteMap = function (_a) {
    var mapId = _a.mapId;
    return api.delete("/managers/maps/" + mapId);
};
export var querySlackWebhookUrl = function (_a) {
    var queryKey = _a.queryKey;
    var data = queryKey[1];
    var mapId = data.mapId;
    return api.get("/managers/maps/" + mapId + "/slack");
};
export var postSlackWebhookUrl = function (_a) {
    var mapId = _a.mapId, slackUrl = _a.slackUrl;
    return api.post("/managers/maps/" + mapId + "/slack", { slackUrl: slackUrl });
};
export var postNotice = function (_a) {
    var mapId = _a.mapId, notice = _a.notice;
    return api.post("/managers/maps/" + mapId + "/notice", { notice: notice });
};
