var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a, _b;
import styled, { css } from 'styled-components';
import { ReservationStatus } from 'types/common';
export var Item = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0.75rem 0;\n  display: flex;\n  gap: 1rem;\n  border-bottom: 1px solid ", ";\n\n  &:last-of-type {\n    border-bottom: none;\n  }\n"], ["\n  padding: 0.75rem 0;\n  display: flex;\n  gap: 1rem;\n  border-bottom: 1px solid ", ";\n\n  &:last-of-type {\n    border-bottom: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
});
var infoStyles = (_a = {},
    _a[ReservationStatus.using] = null,
    _a[ReservationStatus.done] = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.gray[400];
    }),
    _a);
export var InfoWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  ", ";\n"], ["\n  flex: 1;\n  ", ";\n"])), function (_a) {
    var status = _a.status;
    return status && infoStyles[status];
});
export var Info = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: 0.25rem;\n"], ["\n  padding-left: 0.25rem;\n"])));
export var Control = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var Name = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 1rem;\n  line-height: 1.75rem;\n  display: flex;\n  gap: 1rem;\n"], ["\n  font-size: 1rem;\n  line-height: 1.75rem;\n  display: flex;\n  gap: 1rem;\n"])));
export var Description = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  line-height: 1.375rem;\n  color: ", ";\n  ", ";\n"], ["\n  font-size: 0.875rem;\n  line-height: 1.375rem;\n  color: ", ";\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.black[100];
}, function (_a) {
    var status = _a.status;
    return status && infoStyles[status];
});
export var Time = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  line-height: 1.375rem;\n"], ["\n  font-size: 0.875rem;\n  line-height: 1.375rem;\n"])));
var badgeStyles = (_b = {},
    _b[ReservationStatus.using] = css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    border-color: ", ";\n    color: ", ";\n  "], ["\n    border-color: ", ";\n    color: ", ";\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.primary[500];
    }, function (_a) {
        var theme = _a.theme;
        return theme.primary[500];
    }),
    _b[ReservationStatus.done] = css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    border-color: ", ";\n    color: ", ";\n  "], ["\n    border-color: ", ";\n    color: ", ";\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.gray[400];
    }, function (_a) {
        var theme = _a.theme;
        return theme.gray[400];
    }),
    _b);
export var StatusBadge = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: inline-block;\n  padding: 0.25rem 0.375rem;\n  border-width: 1px;\n  border-style: solid;\n  border-radius: 1rem;\n  font-weight: bold;\n  font-size: 0.75rem;\n  ", ";\n"], ["\n  display: inline-block;\n  padding: 0.25rem 0.375rem;\n  border-width: 1px;\n  border-style: solid;\n  border-radius: 1rem;\n  font-weight: bold;\n  font-size: 0.75rem;\n  ", ";\n"])), function (_a) {
    var status = _a.status;
    return status && badgeStyles[status];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
