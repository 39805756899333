var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { queryClient } from 'App';
import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import PATH, { HREF } from 'constants/path';
import { AccessTokenContext } from 'providers/AccessTokenProvider';
import * as Styled from './Header.styles';
var Header = function (_a) {
    var onClickLogin = _a.onClickLogin;
    var location = useLocation();
    var history = useHistory();
    var _b = useContext(AccessTokenContext), accessToken = _b.accessToken, resetAccessToken = _b.resetAccessToken;
    var params = useParams();
    var sharingMapId = params === null || params === void 0 ? void 0 : params.sharingMapId;
    var getHeaderLinkPath = function () {
        if (!accessToken && sharingMapId)
            return HREF.GUEST_MAP(sharingMapId);
        if (!accessToken)
            return PATH.MAIN;
        if (location.pathname.includes('/guest'))
            return PATH.GUEST_MAIN;
        return PATH.MANAGER_MAP_LIST;
    };
    var handleLogout = function () {
        resetAccessToken();
        queryClient.clear();
        if (sharingMapId) {
            history.push(HREF.GUEST_MAP(sharingMapId));
            return;
        }
        history.push(PATH.LOGIN);
    };
    return (_jsx(Styled.Header, { children: _jsxs(Styled.HeaderLayout, { children: [_jsxs(Styled.HeaderLink, __assign({ to: getHeaderLinkPath() }, { children: [_jsx(Styled.Logo, { children: _jsx(LogoIcon, {}, void 0) }, void 0), _jsx(Styled.Title, { children: "\uCC1C\uAF41" }, void 0)] }), void 0), _jsx(Styled.ButtonContainer, { children: accessToken ? (_jsxs(_Fragment, { children: [_jsx(Styled.TextLink, __assign({ to: location.pathname.includes('/guest') ? PATH.GUEST_MAIN : PATH.MANAGER_MAP_LIST }, { children: "\uB9C8\uC774 \uD398\uC774\uC9C0" }), void 0), _jsx(Styled.TextButton, __assign({ variant: "text", onClick: handleLogout }, { children: "\uB85C\uADF8\uC544\uC6C3" }), void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(Styled.TextLink, __assign({ to: PATH.GUEST_NON_LOGIN_RESERVATION_SEARCH }, { children: "\uBE44\uD68C\uC6D0 \uC608\uC57D \uC870\uD68C" }), void 0), _jsx(Styled.TextLink, __assign({ to: sharingMapId ? HREF.GUEST_MAP(sharingMapId) : PATH.LOGIN, onClick: onClickLogin }, { children: "\uB85C\uADF8\uC778" }), void 0), _jsx(Styled.TextLink, __assign({ to: PATH.MANAGER_JOIN }, { children: "\uD68C\uC6D0\uAC00\uC785" }), void 0)] }, void 0)) }, void 0)] }, void 0) }, void 0));
};
export default Header;
