var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var EmojiContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n  width: 112px;\n  height: 112px;\n  padding-bottom: 10px;\n  margin-right: 40px;\n  border-radius: 50%;\n  background-color: ", ";\n  overflow: hidden;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n  width: 112px;\n  height: 112px;\n  padding-bottom: 10px;\n  margin-right: 40px;\n  border-radius: 50%;\n  background-color: ", ";\n  overflow: hidden;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.primary[100];
});
export var Emoji = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 96px;\n"], ["\n  font-size: 96px;\n"])));
export var InfoContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  & > * {\n    margin-bottom: 6px;\n\n    ::last-of-type {\n      margin-bottom: 0;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  & > * {\n    margin-bottom: 6px;\n\n    ::last-of-type {\n      margin-bottom: 0;\n    }\n  }\n"])));
export var NameTextContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 1.5rem;\n  display: flex;\n  align-items: center;\n"], ["\n  font-size: 1.5rem;\n  display: flex;\n  align-items: center;\n"])));
export var NameText = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: bold;\n  margin-right: 0.375rem;\n"], ["\n  font-weight: bold;\n  margin-right: 0.375rem;\n"])));
export var OrganizationTextContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 0.875rem;\n"], ["\n  font-size: 0.875rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
