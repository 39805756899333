var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import ColorDotComponent from 'components/ColorDot/ColorDot';
export var ReservationListWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n"])));
export var ReservationContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 2rem 2rem;\n"], ["\n  padding: 0 2rem 2rem;\n"])));
export var ReservationList = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow-y: auto;\n  & > [role='listitem'] {\n    border-bottom: 1px solid ", ";\n\n    &:last-of-type {\n      border: 0;\n    }\n  }\n"], ["\n  overflow-y: auto;\n  & > [role='listitem'] {\n    border-bottom: 1px solid ", ";\n\n    &:last-of-type {\n      border: 0;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.black[400];
});
export var SpaceTitle = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  font-size: 1.25rem;\n  text-align: center;\n  background-color: ", ";\n"], ["\n  position: sticky;\n  top: 0;\n  font-size: 1.25rem;\n  text-align: center;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.white;
});
export var ColorDot = styled(ColorDotComponent)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: 0.75rem;\n"], ["\n  margin-right: 0.75rem;\n"])));
export var Message = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 1rem 0;\n"], ["\n  padding: 1rem 0;\n"])));
export var IconButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  gap: 0.5rem;\n"], ["\n  display: flex;\n  gap: 0.5rem;\n"])));
export var MessageWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
export var InputWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 1.625rem;\n\n  & > label,\n  & > div {\n    flex: 1;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 1.625rem;\n\n  & > label,\n  & > div {\n    flex: 1;\n  }\n"])));
export var SpaceOption = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 0.75rem;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 0.75rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
