var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Z_INDEX } from 'constants/style';
var labelTextCSS = function (labelText) { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0.375rem;\n\n  &::before {\n    content: '", "';\n    display: block;\n    position: absolute;\n    top: -0.375rem;\n    left: 0.75rem;\n    padding: 0 0.25rem;\n    font-size: 0.75rem;\n    background-color: white;\n    color: ", ";\n  }\n"], ["\n  margin-top: 0.375rem;\n\n  &::before {\n    content: '", "';\n    display: block;\n    position: absolute;\n    top: -0.375rem;\n    left: 0.75rem;\n    padding: 0 0.25rem;\n    font-size: 0.75rem;\n    background-color: white;\n    color: ", ";\n  }\n"])), labelText, function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
}); };
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  z-index: ", ";\n"], ["\n  position: relative;\n  z-index: ", ";\n"])), Z_INDEX.TIME_PICKER_CONTAINER);
export var TimeContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 2.875rem;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  border: 1px solid ", ";\n  border-radius: ", ";\n  position: relative;\n  box-sizing: content-box;\n\n  ", "\n"], ["\n  height: 2.875rem;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  border: 1px solid ", ";\n  border-radius: ", ";\n  position: relative;\n  box-sizing: content-box;\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
}, function (_a) {
    var isOptionOpen = _a.isOptionOpen;
    return (isOptionOpen ? '0.125rem 0.125rem 0 0' : '0.125rem');
}, function (_a) {
    var labelText = _a.labelText;
    return (labelText ? labelTextCSS(labelText) : '');
});
export var TimeButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0 0.5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  white-space: nowrap;\n  min-width: 30%;\n  height: inherit;\n  border: 0;\n  background-color: transparent;\n  letter-spacing: 1px;\n  font-size: 1rem;\n  cursor: pointer;\n\n  ", "\n\n  &:focus {\n    outline-color: ", ";\n  }\n"], ["\n  padding: 0 0.5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  white-space: nowrap;\n  min-width: 30%;\n  height: inherit;\n  border: 0;\n  background-color: transparent;\n  letter-spacing: 1px;\n  font-size: 1rem;\n  cursor: pointer;\n\n  ", "\n\n  &:focus {\n    outline-color: ", ";\n  }\n"])), function (_a) {
    var selected = _a.selected, theme = _a.theme;
    return selected ? "box-shadow: inset 0 -3px " + theme.primary[400] + ";" : '';
}, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
});
export var OptionsContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  position: absolute;\n  user-select: none;\n  z-index: ", ";\n"], ["\n  width: 100%;\n  position: absolute;\n  user-select: none;\n  z-index: ", ";\n"])), Z_INDEX.TIME_PICKER_OPTIONS_CONTAINER);
export var Dimmer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  z-index: ", ";\n"], ["\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  z-index: ", ";\n"])), Z_INDEX.TIME_PICKER_DIMMER);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
