var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/delete.svg';
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg';
import ColorDot from 'components/ColorDot/ColorDot';
import IconButton from 'components/IconButton/IconButton';
import Input from 'components/Input/Input';
import ManagerReservationListItem from 'components/ManagerReservationListItem/ManagerReservationListItem';
import Select from 'components/Select/Select';
import DATE from 'constants/date';
import useGuestReservations from 'hooks/query/useGuestReservations';
import useGuestSpace from 'hooks/query/useGuestSpace';
import { AccessTokenContext } from 'providers/AccessTokenProvider';
import { formatDate, isPastTime } from 'utils/datetime';
import { getReservationStatus } from 'utils/reservation';
import { isNullish } from 'utils/type';
import { GuestMapFormContext } from '../providers/GuestMapFormProvider';
import * as Styled from './ReservationList.styled';
var ReservationList = function (_a) {
    var _b, _c, _d, _e, _f;
    var mapId = _a.map.mapId, 
    // selectedSpaceId,
    onDelete = _a.onDelete, onEdit = _a.onEdit;
    var accessToken = useContext(AccessTokenContext).accessToken;
    var _g = useContext(GuestMapFormContext), spaceList = _g.spaceList, selectedSpaceId = _g.selectedSpaceId, setSelectedSpaceId = _g.setSelectedSpaceId;
    var _h = useState(dayjs().format('YYYY-MM-DD')), date = _h[0], setDate = _h[1];
    var _j = useGuestReservations({
        mapId: mapId,
        spaceId: +selectedSpaceId,
        date: formatDate(dayjs(date)),
    }, {
        enabled: !!selectedSpaceId && !isNullish(selectedSpaceId) && dayjs(date).isValid(),
    }), reservations = _j.data, isLoadingError = _j.isLoadingError, isSuccess = _j.isSuccess;
    var space = useGuestSpace({
        mapId: mapId,
        spaceId: +selectedSpaceId,
    }, {
        enabled: !!selectedSpaceId && !isNullish(selectedSpaceId),
    }).data;
    var handleDate = function (e) {
        setDate(e.target.value);
    };
    console.log('spaceList', spaceList);
    var getSpaceOptions = function () {
        var _a;
        return ((_a = spaceList === null || spaceList === void 0 ? void 0 : spaceList.map(function (space) { return ({
            value: "" + space.id,
            children: (_jsxs(Styled.SpaceOption, { children: [_jsx(ColorDot, { size: "medium", color: space.color }, void 0), space.name] }, void 0)),
        }); })) !== null && _a !== void 0 ? _a : []);
    };
    return (_jsxs(Styled.ReservationListWrapper, { children: [_jsxs(Styled.SpaceTitle, { children: [_jsx(Styled.ColorDot, { color: (_b = space === null || space === void 0 ? void 0 : space.data.color) !== null && _b !== void 0 ? _b : 'transparent' }, void 0), space === null || space === void 0 ? void 0 : space.data.name] }, void 0), _jsx(Input, { type: "date", name: "date", label: "\uB0A0\uC9DC", icon: _jsx(CalendarIcon, {}, void 0), value: date, min: DATE.MIN_DATE_STRING, max: DATE.MAX_DATE_STRING, onChange: handleDate, required: true }, void 0), _jsx(Styled.InputWrapper, { children: _jsx(Select, { name: "space", label: "\uACF5\uAC04 \uC120\uD0DD", options: getSpaceOptions(), value: String(selectedSpaceId), onChange: function (id) { return setSelectedSpaceId === null || setSelectedSpaceId === void 0 ? void 0 : setSelectedSpaceId(id); } }, void 0) }, void 0), _jsxs(_Fragment, { children: [isLoadingError && (_jsxs(Styled.Message, { children: ["\uC608\uC57D \uBAA9\uB85D\uC744 \uBD88\uB7EC\uC624\uB294 \uB370 \uBB38\uC81C\uAC00 \uC0DD\uACBC\uC5B4\uC694!", _jsx("br", {}, void 0), "\uC0C8\uB85C \uACE0\uCE68\uC73C\uB85C \uB2E4\uC2DC \uC2DC\uB3C4\uD574\uC8FC\uC138\uC694."] }, void 0)), isSuccess && ((_c = reservations === null || reservations === void 0 ? void 0 : reservations.data.reservations) === null || _c === void 0 ? void 0 : _c.length) === 0 && (_jsx(Styled.MessageWrapper, { children: _jsx(Styled.Message, { children: "\uC608\uC57D\uC774 \uC5C6\uC2B5\uB2C8\uB2E4." }, void 0) }, void 0)), isSuccess && ((_e = (_d = reservations === null || reservations === void 0 ? void 0 : reservations.data.reservations) === null || _d === void 0 ? void 0 : _d.length) !== null && _e !== void 0 ? _e : 0) > 0 && (_jsx(Styled.ReservationList, __assign({ role: "list" }, { children: (_f = reservations === null || reservations === void 0 ? void 0 : reservations.data.reservations) === null || _f === void 0 ? void 0 : _f.map(function (reservation) {
                            var isControlAvailable = (function () {
                                if (isPastTime(new Date(reservation.endDateTime))) {
                                    return false;
                                }
                                if (!accessToken && reservation.isLoginReservation) {
                                    return false;
                                }
                                if (accessToken && !reservation.isMyReservation) {
                                    return false;
                                }
                                return true;
                            })();
                            return (_jsx(ManagerReservationListItem, { "data-testid": "reservation-" + reservation.id, reservation: reservation, status: getReservationStatus(reservation.startDateTime, reservation.endDateTime), control: isControlAvailable && (_jsxs(Styled.IconButtonWrapper, { children: [_jsx(IconButton, __assign({ size: "small", onClick: function () { return onEdit(reservation); }, "aria-label": "\uC218\uC815" }, { children: _jsx(EditIcon, { width: "100%", height: "100%" }, void 0) }), void 0), _jsx(IconButton, __assign({ size: "small", onClick: function () { return onDelete(reservation); }, "aria-label": "\uC0AD\uC81C" }, { children: _jsx(DeleteIcon, { width: "100%", height: "100%" }, void 0) }), void 0)] }, void 0)) }, reservation.id));
                        }) }), void 0))] }, void 0)] }, void 0));
};
export default ReservationList;
