import axios from 'axios';
import { history } from 'App';
import { BASE_URL } from 'constants/api';
import PATH from 'constants/path';
import { LOCAL_STORAGE_KEY } from 'constants/storage';
import { getLocalStorageItem, removeLocalStorageItem } from 'utils/localStorage';
var api = axios.create({
    baseURL: process.env.DEPLOY_ENV !== 'production' ? BASE_URL.DEV : BASE_URL.PROD,
    headers: {
        'Content-type': 'application/json',
    },
});
api.interceptors.request.use(function (config) {
    var token = getLocalStorageItem({
        key: LOCAL_STORAGE_KEY.ACCESS_TOKEN,
        defaultValue: '',
    });
    if (typeof token !== 'string' || !token)
        return config;
    config.headers = {
        'Content-type': 'application/json',
        Authorization: "Bearer " + token,
    };
    return config;
}, function (error) {
    return Promise.reject(error);
});
api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    var _a;
    if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
        removeLocalStorageItem({ key: LOCAL_STORAGE_KEY.ACCESS_TOKEN });
        history.push(PATH.LOGIN);
    }
    return Promise.reject(error);
});
export default api;
