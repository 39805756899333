var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  position: relative;\n  margin-top: ", ";\n"], ["\n  display: block;\n  position: relative;\n  margin-top: ", ";\n"])), function (_a) {
    var hasLabel = _a.hasLabel;
    return (hasLabel ? '0.5rem' : '0');
});
export var LabelText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  display: inline-block;\n  top: -0.375rem;\n  left: 0.75rem;\n  padding: 0 0.25rem;\n  font-size: 0.75rem;\n  background-color: white;\n  color: ", ";\n"], ["\n  position: absolute;\n  display: inline-block;\n  top: -0.375rem;\n  left: 0.75rem;\n  padding: 0 0.25rem;\n  font-size: 0.75rem;\n  background-color: white;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
});
export var TextAreaWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var TextArea = styled.textarea(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0.75rem;\n  width: 100%;\n  font-size: 1rem;\n  font-family: inherit;\n  border: 1px solid ", ";\n  border-radius: 0.125rem;\n  background: none;\n  outline: none;\n  resize: none;\n\n  &:focus {\n    border-color: ", ";\n    box-shadow: inset 0px 0px 0px 1px ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n  }\n"], ["\n  padding: 0.75rem;\n  width: 100%;\n  font-size: 1rem;\n  font-family: inherit;\n  border: 1px solid ", ";\n  border-radius: 0.125rem;\n  background: none;\n  outline: none;\n  resize: none;\n\n  &:focus {\n    border-color: ", ";\n    box-shadow: inset 0px 0px 0px 1px ", ";\n  }\n\n  &:disabled {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
}, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
});
export var TextLength = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  color: ", ";\n  font-size: 0.75rem;\n  margin: 0.75rem;\n"], ["\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  color: ", ";\n  font-size: 0.75rem;\n  margin: 0.75rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
