import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import useEmojiList from 'hooks/query/useEmojiList';
import * as Styled from './EmojiSelector.styles';
var EmojiSelector = function (_a) {
    var _b;
    var initialEmoji = _a.initialEmoji, onSelect = _a.onSelect;
    var emojiListQuery = useEmojiList();
    var _c = useState(initialEmoji !== null && initialEmoji !== void 0 ? initialEmoji : null), selectedEmoji = _c[0], setSelectedEmoji = _c[1];
    var emojiList = useMemo(function () { var _a, _b; return (_b = (_a = emojiListQuery.data) === null || _a === void 0 ? void 0 : _a.data.emojis) !== null && _b !== void 0 ? _b : []; }, [(_b = emojiListQuery.data) === null || _b === void 0 ? void 0 : _b.data.emojis]);
    var handleSelect = function (emoji) {
        setSelectedEmoji(emoji);
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(emoji);
    };
    return (_jsxs(Styled.EmojiSelector, { children: [_jsx(Styled.LabelText, { children: "\uD504\uB85C\uD544 \uC774\uBAA8\uC9C0 \uC120\uD0DD" }, void 0), _jsx(Styled.EmojiList, { children: emojiList.map(function (emoji) { return (_jsxs(Styled.EmojiItem, { children: [_jsx(Styled.Radio, { type: "radio", name: "emoji", id: emoji.name, checked: selectedEmoji === emoji.name, onChange: function () { return handleSelect(emoji.name); } }, void 0), _jsx(Styled.EmojiCode, { children: emoji.code }, void 0)] }, emoji.name)); }) }, void 0)] }, void 0));
};
export default EmojiSelector;
