var PALETTE = {
    RED: {
        50: '#F49390',
        100: '#F2817D',
        200: '#F06F6A',
        300: '#EE5D58',
        400: '#EC403A',
        500: '#EB3933',
        600: '#E92720',
        700: '#DF1D16',
        800: '#CC1A14',
        900: '#BA1812',
    },
    ORANGE: {
        50: '#F9CFB3',
        100: '#FFAC70',
        200: '#FFA05C',
        300: '#FF9447',
        400: '#FF8833',
        500: '#FF7515',
        600: '#FF700A',
        700: '#F56600',
        800: '#E05E00',
        900: '#CC5500',
    },
    YELLOW: {
        50: '#FFF9B2',
        100: '#FFF79C',
        200: '#FFF57F',
        300: '#FFF37C',
        400: '#FFF176',
        500: '#FFEE58',
        600: '#FFEB3B',
        700: '#FDE63E',
        800: '#FBC02D',
        900: '#F9A825',
    },
    GREEN: {
        50: '#8FE052',
        100: '#84DD40',
        200: '#79DA2F',
        300: '#6FD025',
        400: '#66BF22',
        500: '#5CAC1E',
        600: '#539C1C',
        700: '#4A8B18',
        800: '#417915',
        900: '#376812',
    },
    BLUE: {
        50: '#E3E7F9',
        100: '#C5D9F9',
        200: '#9CD0F9',
        300: '#6BC2F9',
        400: '#42B4F9',
        500: '#1EACFC',
        600: '#0D9FFF',
        700: '#049FFF',
        800: '#0080FF',
        900: '#0060FF',
    },
    PURPLE: {
        50: '##F5F3FF',
        100: '#EDE9FE',
        200: '#DDD6FE',
        300: '#C4B5FD',
        400: '#A78BFA',
        500: '#8B5CF6',
        600: '#7C3AED',
        700: '#6D28D9',
        800: '#5B21B6',
        900: '#4C1D95',
    },
    BLACK: {
        100: '#666666',
        200: '#555555',
        300: '#444444',
        400: '#333333',
        500: '#222222',
        600: '#111111',
        700: '#000000',
    },
    GRAY: {
        50: '#FAFAFA',
        100: '#F4F4F5',
        200: '#E4E4E7',
        300: '#D4D4D8',
        400: '#A1A1AA',
        500: '#71717A',
        600: '#52525B',
        700: '#3F3F46',
        800: '#27272A',
        900: '#18181B',
    },
    OPACITY_GRAY: 'rgba(212, 212, 216, 0.3)',
    OPACITY_BLACK: {
        50: 'rgba(0, 0, 0, 0.05)',
        100: 'rgba(0, 0, 0, 0.1)',
        200: 'rgba(0, 0, 0, 0.2)',
        300: 'rgba(0, 0, 0, 0.3)',
        400: 'rgba(0, 0, 0, 0.4)',
        500: 'rgba(0, 0, 0, 0.5)',
        600: 'rgba(0, 0, 0, 0.6)',
        700: 'rgba(0, 0, 0, 0.7)',
        800: 'rgba(0, 0, 0, 0.8)',
        900: 'rgba(0, 0, 0, 0.9)',
    },
    WHITE: '#fff',
    GITHUB: '#24292F',
};
export default PALETTE;
