var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { postLogin } from 'api/login';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Modal from 'components/Modal/Modal';
import SocialLoginButton from 'components/SocialAuthButton/SocialLoginButton';
import MANAGER from 'constants/manager';
import MESSAGE from 'constants/message';
import PATH from 'constants/path';
import { LOCAL_STORAGE_KEY } from 'constants/storage';
import useInputs from 'hooks/useInputs';
import { AccessTokenContext } from 'providers/AccessTokenProvider';
import { setLocalStorageItem } from 'utils/localStorage';
import * as Styled from './LoginPopup.styles';
var LoginPopup = function (_a) {
    var open = _a.open, onClose = _a.onClose, onLogin = _a.onLogin;
    var setAccessToken = useContext(AccessTokenContext).setAccessToken;
    var history = useHistory();
    var location = useLocation();
    var _b = useInputs({
        email: '',
        password: '',
    }), _c = _b[0], email = _c.email, password = _c.password, onChangeForm = _b[1], setValues = _b[2];
    var _d = useState({
        email: undefined,
        password: undefined,
    }), loginErrorMessage = _d[0], setLoginErrorMessage = _d[1];
    var login = useMutation(postLogin, {
        onSuccess: function (response) {
            var accessToken = response.data.accessToken;
            setAccessToken(accessToken);
            setValues({ email: '', password: '' });
            onLogin === null || onLogin === void 0 ? void 0 : onLogin();
        },
        onError: function (error) {
            var _a;
            var _b, _c;
            var field = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data.field;
            var message = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data.message;
            if (field && message) {
                setLoginErrorMessage((_a = {}, _a[field] = message, _a));
                return;
            }
            setLoginErrorMessage({ password: message !== null && message !== void 0 ? message : MESSAGE.LOGIN.UNEXPECTED_ERROR });
        },
    });
    var handleSubmitLogin = function (event) {
        event.preventDefault();
        login.mutate({ email: email, password: password });
    };
    return (_jsx(Modal, __assign({ open: open, onClose: onClose }, { children: _jsxs(Styled.LoginPopupWrapper, { children: [_jsxs(Styled.LoginPopupHeading, { children: ["\uB85C\uADF8\uC778 \uD6C4 ", _jsx("strong", { children: "\uB098\uC758 \uC608\uC57D \uB0B4\uC5ED" }, void 0), "\uC744 \uAD00\uB9AC\uD574\uBCF4\uC138\uC694!"] }, void 0), _jsxs(Styled.LoginPopupForm, __assign({ onSubmit: handleSubmitLogin }, { children: [_jsxs(Styled.LoginFormInputWrapper, { children: [_jsx(Input, { type: "email", label: "\uC774\uBA54\uC77C", name: "email", value: email, onChange: onChangeForm, message: loginErrorMessage === null || loginErrorMessage === void 0 ? void 0 : loginErrorMessage.email, status: (loginErrorMessage === null || loginErrorMessage === void 0 ? void 0 : loginErrorMessage.email) ? 'error' : 'default', autoFocus: true, required: true }, void 0), _jsx(Input, { type: "password", label: "\uBE44\uBC00\uBC88\uD638", name: "password", value: password, minLength: MANAGER.PASSWORD.MIN_LENGTH, maxLength: MANAGER.PASSWORD.MAX_LENGTH, onChange: onChangeForm, message: loginErrorMessage === null || loginErrorMessage === void 0 ? void 0 : loginErrorMessage.password, status: (loginErrorMessage === null || loginErrorMessage === void 0 ? void 0 : loginErrorMessage.password) ? 'error' : 'default', required: true }, void 0)] }, void 0), _jsxs(Styled.LoginFormButtonWrapper, { children: [_jsx(Button, __assign({ type: "submit", variant: "primary", size: "medium", fullWidth: true }, { children: "\uB85C\uADF8\uC778" }), void 0), _jsx(Button, __assign({ type: "button", variant: "inverse", size: "medium", fullWidth: true, onClick: function () { return history.push(PATH.MANAGER_JOIN); } }, { children: "\uD68C\uC6D0\uAC00\uC785" }), void 0)] }, void 0)] }), void 0), _jsx(Styled.Line, {}, void 0), _jsxs(Styled.SocialLoginButtonWrapper, { children: [_jsx(SocialLoginButton, { provider: "GITHUB", variant: "icon", onClick: function () {
                                setLocalStorageItem({
                                    key: LOCAL_STORAGE_KEY.AFTER_LOGIN_PATH,
                                    item: location.pathname,
                                });
                                window.location.href = PATH.GITHUB_LOGIN;
                            } }, void 0), _jsx(SocialLoginButton, { provider: "GOOGLE", variant: "icon", onClick: function () {
                                setLocalStorageItem({
                                    key: LOCAL_STORAGE_KEY.AFTER_LOGIN_PATH,
                                    item: location.pathname,
                                });
                                window.location.href = PATH.GOOGLE_LOGIN;
                            } }, void 0)] }, void 0), _jsx(Styled.ContinueWithNonMemberWrapper, { children: _jsx(Styled.ContinueWithNonMember, __assign({ onClick: onClose }, { children: "\uBE44\uD68C\uC6D0\uC73C\uB85C \uACC4\uC18D\uD558\uAE30" }), void 0) }, void 0)] }, void 0) }), void 0));
};
export default LoginPopup;
