var MANAGER = {
    PASSWORD: {
        MIN_LENGTH: 8,
        MAX_LENGTH: 20,
    },
    USERNAME: {
        MIN_LENGTH: 1,
        MAX_LENGTH: 20,
    },
    ORGANIZATION: {
        MIN_LENGTH: 1,
    },
};
export default MANAGER;
