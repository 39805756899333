var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { EDITOR } from 'constants/editor';
import PALETTE from 'constants/palette';
import { DrawingAreaShape } from 'types/editor';
import { getPolygonCenterPoint } from 'utils/editor';
import { GuestMapFormContext } from '../providers/GuestMapFormProvider';
import * as Styled from './GuestMapDrawing.styles';
var GuestMapDrawing = function (_a) {
    var mapDrawing = _a.mapDrawing, spaceList = _a.spaceList, onClickSpaceArea = _a.onClickSpaceArea, isReservation = _a.isReservation;
    var availableSpaceList = useContext(GuestMapFormContext).availableSpaceList;
    var availableSpace = useMemo(function () {
        var _a;
        return ((_a = availableSpaceList === null || availableSpaceList === void 0 ? void 0 : availableSpaceList.reduce(function (acc, cur) {
            acc[cur.id] = cur;
            return acc;
        }, {})) !== null && _a !== void 0 ? _a : {});
    }, [availableSpaceList]);
    return (_jsx(Styled.MapItem, __assign({ width: mapDrawing.width, height: mapDrawing.height }, { children: _jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", version: "1.1", width: mapDrawing.width, height: mapDrawing.height }, { children: [mapDrawing.mapElements.map(function (element) {
                    return element.type === 'polyline' ? (_jsx("polyline", { points: element.points.join(' '), stroke: element.stroke, strokeWidth: EDITOR.STROKE_WIDTH, strokeLinecap: "round" }, "polyline-" + element.id)) : (_jsx("rect", { x: element === null || element === void 0 ? void 0 : element.x, y: element === null || element === void 0 ? void 0 : element.y, width: element === null || element === void 0 ? void 0 : element.width, height: element === null || element === void 0 ? void 0 : element.height, stroke: element.stroke, fill: "none", strokeWidth: EDITOR.STROKE_WIDTH }, "rect-" + element.id));
                }), spaceList.length > 0 &&
                    spaceList.map(function (_a) {
                        var id = _a.id, area = _a.area, color = _a.color, name = _a.name;
                        return (_jsxs(Styled.Space, __assign({ "data-testid": id, onClick: function () { return onClickSpaceArea(id); } }, { children: [area.shape === DrawingAreaShape.Rect && (_jsxs(_Fragment, { children: [_jsx(Styled.SpaceRect, { x: area.x, y: area.y, width: area.width, height: area.height, fill: color !== null && color !== void 0 ? color : PALETTE.RED[200], opacity: "0.5", disabled: isReservation && !availableSpace[id] }, void 0), _jsx(Styled.SpaceAreaText, __assign({ x: area.x + area.width / 2, y: area.y + area.height / 2 }, { children: name }), void 0)] }, void 0)), area.shape === DrawingAreaShape.Polygon && (_jsxs(_Fragment, { children: [_jsx(Styled.SpacePolygon, { points: area.points.map(function (_a) {
                                                var x = _a.x, y = _a.y;
                                                return x + "," + y;
                                            }).join(' '), fill: color !== null && color !== void 0 ? color : PALETTE.RED[200], opacity: "0.5", disabled: isReservation && !availableSpace[id] }, void 0), _jsx(Styled.SpaceAreaText, __assign({ x: getPolygonCenterPoint(area.points).x, y: getPolygonCenterPoint(area.points).y }, { children: name }), void 0)] }, void 0))] }), "area-" + id));
                    })] }), void 0) }), void 0));
};
export default GuestMapDrawing;
