export var MapEditorMode;
(function (MapEditorMode) {
    MapEditorMode["Select"] = "select";
    MapEditorMode["Move"] = "move";
    MapEditorMode["Line"] = "line";
    MapEditorMode["Rect"] = "rect";
    MapEditorMode["Eraser"] = "eraser";
    MapEditorMode["Decoration"] = "decoration";
})(MapEditorMode || (MapEditorMode = {}));
export var SpaceEditorMode;
(function (SpaceEditorMode) {
    SpaceEditorMode["Default"] = "default";
    SpaceEditorMode["Move"] = "move";
    SpaceEditorMode["Zoom"] = "zoom";
    SpaceEditorMode["Rect"] = "rect";
    SpaceEditorMode["Polygon"] = "polygon";
    SpaceEditorMode["Form"] = "form";
})(SpaceEditorMode || (SpaceEditorMode = {}));
export var MapElementType;
(function (MapElementType) {
    MapElementType["Polyline"] = "polyline";
    MapElementType["Rect"] = "rect";
    MapElementType["Polygon"] = "polygon";
})(MapElementType || (MapElementType = {}));
export var DrawingAreaShape;
(function (DrawingAreaShape) {
    DrawingAreaShape["Rect"] = "rect";
    DrawingAreaShape["Polygon"] = "polygon";
})(DrawingAreaShape || (DrawingAreaShape = {}));
