var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { deleteGuestReservation } from 'api/guestReservation';
import Header from 'components/Header/Header';
import MESSAGE from 'constants/message';
import { HREF } from 'constants/path';
import useGuestReservations from 'hooks/query/useGuestReservations';
import useGuestSpaces from 'hooks/query/useGuestSpaces';
import { AccessTokenContext } from 'providers/AccessTokenProvider';
import { formatDate } from 'utils/datetime';
import { isNullish } from 'utils/type';
import * as Styled from './GuestMap.styles';
import { GuestMapFormContext } from './providers/GuestMapFormProvider';
import Aside from './units/Aside';
import GuestMapDrawing from './units/GuestMapDrawing';
import LoginPopup from './units/LoginPopup';
import PasswordInputModal from './units/PasswordInputModal';
export var SWITCH_LABEL_LIST = ['예약하기', '예약현황'];
var GuestMap = function (_a) {
    var _b, _c, _d;
    var map = _a.map;
    var accessToken = useContext(AccessTokenContext).accessToken;
    var setSelectedSpaceIdForm = useContext(GuestMapFormContext).setSelectedSpaceId;
    var _e = useState(false), passwordInputModalOpen = _e[0], setPasswordInputModalOpen = _e[1];
    var _f = useState(), selectedReservation = _f[0], setSelectedReservation = _f[1];
    var _g = useState(true), loginPopupOpen = _g[0], setLoginPopupOpen = _g[1];
    var history = useHistory();
    var location = useLocation();
    var sharingMapId = useParams().sharingMapId;
    var mapRef = useRef(null);
    var spaceId = (_b = location.state) === null || _b === void 0 ? void 0 : _b.spaceId;
    var targetDate = (_c = location.state) === null || _c === void 0 ? void 0 : _c.targetDate;
    var scrollPosition = (_d = location.state) === null || _d === void 0 ? void 0 : _d.scrollPosition;
    var mapDrawing = map.mapDrawing;
    var _h = useState([]), spaceList = _h[0], setSpaceList = _h[1];
    var _j = useState(spaceId !== null && spaceId !== void 0 ? spaceId : null), selectedSpaceId = _j[0], setSelectedSpaceId = _j[1];
    var _k = useState(targetDate ? dayjs(targetDate).tz() : dayjs().tz()), date = _k[0], setDate = _k[1];
    var _l = useState(SWITCH_LABEL_LIST[0]), selectedSwitchLabel = _l[0], setSelectedSwitchLabel = _l[1];
    var spaces = useMemo(function () {
        var result = {};
        spaceList.forEach(function (item) { return (result[item.id] = item); });
        return result;
    }, [spaceList]);
    useGuestSpaces({ mapId: map.mapId }, {
        enabled: map.mapId !== undefined,
        onSuccess: function (response) {
            var spaces = response.data.spaces;
            setSpaceList(spaces.map(function (space) { return (__assign(__assign({}, space), { area: JSON.parse(space.area) })); }));
            if (selectedSpaceId === null) {
                setSelectedSpaceId(spaces[0].id);
            }
        },
    });
    var getReservations = useGuestReservations({
        mapId: map.mapId,
        spaceId: selectedSpaceId,
        date: formatDate(date),
    }, {
        enabled: !isNullish(selectedSpaceId) && dayjs(date).isValid(),
    });
    var removeReservation = useMutation(deleteGuestReservation, {
        onSuccess: function () {
            getReservations.refetch();
            window.alert(MESSAGE.RESERVATION.DELETE_SUCCESS);
            setPasswordInputModalOpen(false);
        },
        onError: function (error) {
            var _a, _b;
            alert((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message) !== null && _b !== void 0 ? _b : MESSAGE.RESERVATION.UNEXPECTED_DELETE_ERROR);
        },
    });
    var handleClickSwitch = function (label) {
        setSelectedSwitchLabel(label);
    };
    var handleClickSpaceArea = function (spaceId) {
        if (selectedSwitchLabel === SWITCH_LABEL_LIST[0]) {
            setSelectedSpaceIdForm === null || setSelectedSpaceIdForm === void 0 ? void 0 : setSelectedSpaceIdForm("" + spaceId);
        }
        else if (selectedSwitchLabel === SWITCH_LABEL_LIST[1]) {
            setSelectedSpaceId(spaceId);
            setSelectedSpaceIdForm === null || setSelectedSpaceIdForm === void 0 ? void 0 : setSelectedSpaceIdForm("" + spaceId);
        }
    };
    var deleteLoginReservation = function (reservationId) {
        if (typeof map.mapId !== 'number' || selectedSpaceId === null)
            return;
        if (!window.confirm(MESSAGE.GUEST_MAP.DELETE_CONFIRM))
            return;
        removeReservation.mutate({
            mapId: map.mapId,
            spaceId: selectedSpaceId,
            reservationId: reservationId,
        });
    };
    var handleDeleteGuestReservation = function (passwordInput) {
        if (typeof map.mapId !== 'number' || selectedSpaceId === null)
            return;
        removeReservation.mutate({
            mapId: map.mapId,
            spaceId: selectedSpaceId,
            password: passwordInput,
            reservationId: Number(selectedReservation === null || selectedReservation === void 0 ? void 0 : selectedReservation.id),
        });
    };
    var handleDelete = function (reservation) {
        if (!reservation.isLoginReservation) {
            setPasswordInputModalOpen(true);
            setSelectedReservation(reservation);
        }
        else {
            deleteLoginReservation(reservation.id);
        }
    };
    var handleEdit = function (reservation) {
        var _a, _b;
        if (!selectedSpaceId)
            return;
        history.push({
            pathname: HREF.GUEST_RESERVATION_EDIT(sharingMapId),
            state: {
                mapId: map.mapId,
                spaceId: spaces[selectedSpaceId].id,
                reservation: reservation,
                selectedDate: formatDate(date),
                scrollPosition: { x: (_a = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft, y: (_b = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _b === void 0 ? void 0 : _b.scrollTop },
            },
        });
    };
    var handleLogin = function () {
        getReservations.refetch();
    };
    useEffect(function () {
        var _a, _b, _c;
        if (scrollPosition) {
            (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo((_b = scrollPosition.x) !== null && _b !== void 0 ? _b : 0, (_c = scrollPosition.y) !== null && _c !== void 0 ? _c : 0);
        }
    }, [scrollPosition]);
    useEffect(function () {
        if (targetDate) {
            setDate(dayjs(targetDate).tz());
        }
    }, [targetDate]);
    return (_jsxs(_Fragment, { children: [_jsx(Aside, { map: map, selectedLabel: selectedSwitchLabel, onClickSwitch: handleClickSwitch, selectedSpaceId: selectedSpaceId, onEdit: handleEdit, onDelete: handleDelete }, void 0), _jsxs(Styled.MapContainer, __assign({ ref: mapRef }, { children: [_jsx(Styled.HeaderWrapper, { children: _jsx(Header, { onClickLogin: function () { return setLoginPopupOpen(true); } }, void 0) }, void 0), mapDrawing && (_jsx(GuestMapDrawing, { isReservation: selectedSwitchLabel === SWITCH_LABEL_LIST[0], mapDrawing: mapDrawing, spaceList: spaceList, onClickSpaceArea: handleClickSpaceArea }, void 0))] }), void 0), _jsx(PasswordInputModal, { open: passwordInputModalOpen, onClose: function () { return setPasswordInputModalOpen(false); }, onSubmit: handleDeleteGuestReservation }, void 0), !accessToken && (_jsx(LoginPopup, { open: loginPopupOpen, onClose: function () { return setLoginPopupOpen(false); }, onLogin: handleLogin }, void 0))] }, void 0));
};
export default GuestMap;
