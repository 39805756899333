var _a;
import PATH from './path';
export var TAB_LABEL = {
    MANAGER: '관리자',
    GUEST: '예약자',
};
export var TAB_LIST = [TAB_LABEL.MANAGER, TAB_LABEL.GUEST];
export var TAB_PATH_FOR_LABEL = (_a = {},
    _a[TAB_LABEL.MANAGER] = PATH.MANAGER_MAP_LIST,
    _a[TAB_LABEL.GUEST] = PATH.GUEST_MAIN,
    _a);
