var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var disabledCSS = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  opacity: 0.2;\n  filter: grayscale(1) brightness(3);\n  pointer-events: none;\n"], ["\n  opacity: 0.2;\n  filter: grayscale(1) brightness(3);\n  pointer-events: none;\n"])));
export var MapItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  padding: 1.5rem;\n  min-width: ", "px;\n  min-height: ", "px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  @media (max-width: ", "px) {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"], ["\n  flex: 1;\n  padding: 1.5rem;\n  min-width: ", "px;\n  min-height: ", "px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  @media (max-width: ", "px) {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"])), function (_a) {
    var width = _a.width;
    return width;
}, function (_a) {
    var height = _a.height;
    return height;
}, function (_a) {
    var width = _a.width;
    return width;
});
export var Space = styled.g(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export var SpaceRect = styled.rect(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &:hover {\n    opacity: 0.7;\n  }\n\n  ", ";\n"], ["\n  &:hover {\n    opacity: 0.7;\n  }\n\n  ", ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && disabledCSS;
});
export var SpacePolygon = styled.polygon(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &:hover {\n    opacity: 0.7;\n  }\n\n  ", ";\n"], ["\n  &:hover {\n    opacity: 0.7;\n  }\n\n  ", ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && disabledCSS;
});
export var SpaceAreaText = styled.text(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  dominant-baseline: middle;\n  text-anchor: middle;\n  fill: ", ";\n  font-size: 1rem;\n  pointer-events: none;\n  user-select: none;\n"], ["\n  dominant-baseline: middle;\n  text-anchor: middle;\n  fill: ", ";\n  font-size: 1rem;\n  pointer-events: none;\n  user-select: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.black[700];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
