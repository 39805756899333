var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  height: calc(100vh - 8rem);\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  height: calc(100vh - 8rem);\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])));
export var PageHeader = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.5rem;\n  font-weight: 700;\n  margin: 0.75rem 0 1.25rem;\n"], ["\n  font-size: 1.5rem;\n  font-weight: 700;\n  margin: 0.75rem 0 1.25rem;\n"])));
export var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var HomeLink = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1.25rem;\n  text-decoration: none;\n  color: ", ";\n"], ["\n  font-size: 1.25rem;\n  text-decoration: none;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
