import PALETTE from './palette';
export var MAP_COLOR_PALETTE = [
    PALETTE.BLACK[400],
    PALETTE.GRAY[400],
    PALETTE.RED[900],
    PALETTE.ORANGE[900],
    PALETTE.YELLOW[900],
    PALETTE.GREEN[900],
    PALETTE.BLUE[900],
    PALETTE.PURPLE[900],
];
export var BOARD = {
    DEFAULT_WIDTH: 800,
    DEFAULT_HEIGHT: 600,
    MAX_WIDTH: 5000,
    MIN_WIDTH: 100,
    MAX_HEIGHT: 5000,
    MIN_HEIGHT: 100,
};
export var KEY = {
    DELETE: 'Delete',
    BACK_SPACE: 'Backspace',
    SPACE: ' ',
    ENTER: 'Enter',
};
export var EDITOR = {
    GRID_SIZE: 10,
    SCALE_DELTA: 0.001,
    MIN_SCALE: 0.5,
    MAX_SCALE: 3.0,
    STROKE_WIDTH: 3,
    STROKE_PREVIEW: PALETTE.OPACITY_BLACK[200],
    POLYGON_PREVIEW_FILL: PALETTE.OPACITY_BLACK[200],
    MIN_POLYGON_SIDES: 3,
    OPACITY: 1,
    OPACITY_DELETING: 0.3,
    TEXT_OPACITY: 0.3,
    TEXT_FONT_SIZE: '1rem',
    TEXT_FILL: PALETTE.BLACK[700],
    SPACE_OPACITY: 0.1,
    CIRCLE_CURSOR_RADIUS: 3,
    CIRCLE_PREVIEW_RADIUS: 5,
    CIRCLE_CURSOR_FILL: PALETTE.OPACITY_BLACK[300],
    DRAG_SELECT_RECT_FILL: PALETTE.OPACITY_BLACK[200],
    SELECTED_GROUP_BBOX_STROKE_WIDTH: 2,
    SELECTED_GROUP_BBOX_POSITION_OFFSET: 2,
    get SELECTED_GROUP_BBOX_MARGIN_OFFSET() {
        return this.SELECTED_GROUP_BBOX_POSITION_OFFSET * 2;
    },
};
