var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as Styled from './Tab.styles';
var Tab = function (_a) {
    var tabList = _a.tabList, defaultTabLabel = _a.defaultTabLabel, onClick = _a.onClick;
    return (_jsx(Styled.Container, { children: tabList.map(function (tab) { return (_jsx(Styled.TabItem, __assign({ onClick: function () { return onClick(tab); }, selected: tab === defaultTabLabel }, { children: tab }), tab)); }) }, void 0));
};
export default Tab;
