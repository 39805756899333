var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { theme } from 'App.styles';
import { ReactComponent as CaretIcon } from 'assets/svg/caret-right.svg';
import PATH from 'constants/path';
import useMember from 'hooks/query/useMember';
import * as Styled from './MemberInfo.styled';
var MemberInfo = function () {
    var data = useMember().data;
    return (_jsxs(Styled.Container, { children: [_jsx(Styled.EmojiContainer, { children: _jsx(Styled.Emoji, { children: data === null || data === void 0 ? void 0 : data.data.emoji.code }, void 0) }, void 0), _jsxs(Styled.InfoContainer, { children: [_jsxs(Styled.NameTextContainer, { children: [_jsx(Styled.NameText, { children: data === null || data === void 0 ? void 0 : data.data.userName }, void 0), "\uB2D8", _jsx(Link, __assign({ to: PATH.MANAGER_PROFILE_EDIT }, { children: _jsx(CaretIcon, { width: 36, height: 36, fill: theme.gray[400] }, void 0) }), void 0)] }, void 0), (data === null || data === void 0 ? void 0 : data.data.organization) && (_jsx(Styled.OrganizationTextContainer, { children: data === null || data === void 0 ? void 0 : data.data.organization }, void 0))] }, void 0)] }, void 0));
};
export default MemberInfo;
