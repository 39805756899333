var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { putMember } from 'api/member';
import Header from 'components/Header/Header';
import Layout from 'components/Layout/Layout';
import MESSAGE from 'constants/message';
import PATH from 'constants/path';
import useMember from 'hooks/query/useMember';
import * as Styled from './ManagerProfileEdit.styles';
import ProfileEditForm from './units/ProfileEditForm';
var ManagerProfileEdit = function () {
    var _a;
    var history = useHistory();
    var member = useMember();
    var isOAuthMember = ((_a = member === null || member === void 0 ? void 0 : member.data) === null || _a === void 0 ? void 0 : _a.data.oauthProvider) !== null;
    var editProfile = useMutation(putMember, {
        onSuccess: function () {
            history.push(PATH.MANAGER_MAP_LIST);
        },
        onError: function (error) {
            var _a, _b;
            alert((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data.message) !== null && _b !== void 0 ? _b : MESSAGE.MEMBER.EDIT_PROFILE_UNEXPECTED_ERROR);
        },
    });
    var handleSubmit = function (_a) {
        var userName = _a.userName, emoji = _a.emoji;
        editProfile.mutate({ userName: userName, emoji: emoji });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}, void 0), _jsx(Layout, { children: _jsxs(Styled.Container, { children: [_jsx(Styled.PageTitle, { children: "\uB0B4 \uC815\uBCF4 \uC218\uC815" }, void 0), _jsx(ProfileEditForm, { onSubmit: handleSubmit }, void 0), !isOAuthMember && (_jsxs(Styled.PasswordChangeLinkMessage, { children: ["\uBE44\uBC00\uBC88\uD638\uB97C \uBCC0\uACBD\uD558\uACE0 \uC2F6\uC73C\uC2E0\uAC00\uC694?", _jsx(Link, __assign({ to: PATH.MANAGER_PASSWORD_EDIT }, { children: "\uBCC0\uACBD\uD558\uAE30" }), void 0)] }, void 0))] }, void 0) }, void 0)] }, void 0));
};
export default ManagerProfileEdit;
