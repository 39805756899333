var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var LoginPopupWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 2rem 3rem;\n"], ["\n  padding: 2rem 3rem;\n"])));
export var LoginPopupHeading = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.5rem;\n  text-align: center;\n  margin-bottom: 1.5rem;\n\n  strong {\n    font-weight: bold;\n  }\n\n  @media (max-width: ", "px) {\n    font-size: 1.125rem;\n  }\n"], ["\n  font-size: 1.5rem;\n  text-align: center;\n  margin-bottom: 1.5rem;\n\n  strong {\n    font-weight: bold;\n  }\n\n  @media (max-width: ", "px) {\n    font-size: 1.125rem;\n  }\n"])), function (_a) {
    var breakpoints = _a.theme.breakpoints;
    return breakpoints.md;
});
export var LoginPopupForm = styled.form(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n"])));
export var LoginFormInputWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n"])));
export var LoginFormButtonWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n"])));
export var Line = styled.hr(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 1px;\n  border-width: 0px;\n  color: ", ";\n  background-color: ", ";\n  margin: 1rem 0;\n"], ["\n  height: 1px;\n  border-width: 0px;\n  color: ", ";\n  background-color: ", ";\n  margin: 1rem 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[300];
}, function (_a) {
    var theme = _a.theme;
    return theme.gray[300];
});
export var SocialLoginButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  gap: 1rem;\n  margin-bottom: 1rem;\n"], ["\n  display: flex;\n  justify-content: center;\n  gap: 1rem;\n  margin-bottom: 1rem;\n"])));
export var ContinueWithNonMemberWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var ContinueWithNonMember = styled.a(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 0.75rem;\n  color: ", ";\n  text-decoration: underline;\n  cursor: pointer;\n"], ["\n  display: inline-block;\n  font-size: 0.75rem;\n  color: ", ";\n  text-decoration: underline;\n  cursor: pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
