var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import * as Styled from './TextArea.styles';
var TextArea = function (_a) {
    var _b;
    var label = _a.label, value = _a.value, props = __rest(_a, ["label", "value"]);
    var ref = useRef(null);
    var _c = useState(0), valueLength = _c[0], setValueLength = _c[1];
    var handleChange = function (event) {
        var _a, _b, _c;
        setValueLength((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.value.length) !== null && _b !== void 0 ? _b : 0);
        (_c = props.onChange) === null || _c === void 0 ? void 0 : _c.call(props, event);
    };
    return (_jsxs(Styled.Label, __assign({ hasLabel: !!label }, { children: [label && _jsx(Styled.LabelText, { children: label }, void 0), _jsx(Styled.TextArea, __assign({}, props, { ref: ref, value: value, onChange: handleChange }), void 0), _jsxs(Styled.TextLength, { children: [(_b = value === null || value === void 0 ? void 0 : value.length) !== null && _b !== void 0 ? _b : valueLength, props.maxLength ? " / " + props.maxLength : ''] }, void 0)] }), void 0));
};
export default TextArea;
