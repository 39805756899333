var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Page = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var HeaderWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  width: calc(100% - 576px);\n"], ["\n  position: fixed;\n  width: calc(100% - 576px);\n"])));
export var MapContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100vh;\n  flex: 1;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"], ["\n  height: 100vh;\n  flex: 1;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"])), function (_a) {
    var breakpoints = _a.theme.breakpoints;
    return breakpoints.sm;
});
export var SelectBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var SelectButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  border: none;\n  cursor: pointer;\n  background-color: white;\n  padding: 1rem 1.5rem;\n  text-align: left;\n  font-size: 1rem;\n\n  svg {\n    margin-top: 0.25rem;\n    margin-right: 0.5rem;\n    fill: ", ";\n  }\n\n  &:hover {\n    opacity: 0.7;\n  }\n\n  &:first-child {\n    border-bottom: 1px solid rgba(196, 196, 196, 0.3);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  border: none;\n  cursor: pointer;\n  background-color: white;\n  padding: 1rem 1.5rem;\n  text-align: left;\n  font-size: 1rem;\n\n  svg {\n    margin-top: 0.25rem;\n    margin-right: 0.5rem;\n    fill: ", ";\n  }\n\n  &:hover {\n    opacity: 0.7;\n  }\n\n  &:first-child {\n    border-bottom: 1px solid rgba(196, 196, 196, 0.3);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
