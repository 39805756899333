var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { queryValidateUserName } from 'api/join';
import Button from 'components/Button/Button';
import EmojiSelector from 'components/EmojiSelector/EmojiSelector';
import Input from 'components/Input/Input';
import MANAGER from 'constants/manager';
import MESSAGE from 'constants/message';
import PATH from 'constants/path';
import useMember from 'hooks/query/useMember';
import useInputs from 'hooks/useInputs';
import * as Styled from './ProfileEditForm.styles';
var ProfileEditForm = function (_a) {
    var _b, _c;
    var onSubmit = _a.onSubmit;
    var history = useHistory();
    var member = useMember();
    var initialUserName = (_b = member.data) === null || _b === void 0 ? void 0 : _b.data.userName;
    var initialEmoji = (_c = member.data) === null || _c === void 0 ? void 0 : _c.data.emoji.name;
    var _d = useState(''), emoji = _d[0], setEmoji = _d[1];
    var _e = useInputs({
        userName: '',
    }), userName = _e[0].userName, onChangeForm = _e[1], setInputs = _e[2];
    var _f = useState(''), userNameMessage = _f[0], setUserNameMessage = _f[1];
    var checkValidateUserName = useQuery(['checkValidateUserName', userName], queryValidateUserName, {
        enabled: false,
        retry: false,
        onSuccess: function () {
            setUserNameMessage(MESSAGE.JOIN.VALID_USERNAME);
        },
        onError: function (error) {
            var _a, _b;
            setUserNameMessage((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message) !== null && _b !== void 0 ? _b : MESSAGE.JOIN.CHECK_USERNAME_UNEXPECTED_ERROR);
        },
    });
    var handleSelectEmoji = function (emoji) {
        setEmoji(emoji);
    };
    var handleChangeUserName = function (event) {
        onChangeForm(event);
        setUserNameMessage('');
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        onSubmit({ userName: userName, emoji: emoji });
    };
    var isSubmitButtonDisabled = !(emoji && userName);
    var handleCancel = function () {
        history.push(PATH.MANAGER_MAP_LIST);
    };
    useEffect(function () {
        if (!initialUserName)
            return;
        setInputs(function (prevInputs) { return (__assign(__assign({}, prevInputs), { userName: initialUserName })); });
    }, [initialUserName, setInputs]);
    useEffect(function () {
        if (!initialEmoji)
            return;
        setEmoji(initialEmoji);
    }, [initialEmoji]);
    return (_jsxs(Styled.Form, __assign({ onSubmit: handleSubmit }, { children: [initialEmoji && _jsx(EmojiSelector, { initialEmoji: initialEmoji, onSelect: handleSelectEmoji }, void 0), initialUserName && (_jsx(Styled.InputWrapper, { children: _jsx(Input, { type: "text", label: "\uC774\uB984", name: "userName", minLength: MANAGER.USERNAME.MIN_LENGTH, maxLength: MANAGER.USERNAME.MAX_LENGTH, value: userName, onChange: handleChangeUserName, message: userNameMessage, status: checkValidateUserName.isSuccess ? 'success' : 'error', required: true }, void 0) }, void 0)), _jsxs(Styled.ButtonContainer, { children: [_jsx(Button, __assign({ size: "large", type: "button", fullWidth: true, onClick: handleCancel }, { children: "\uCDE8\uC18C" }), void 0), _jsx(Button, __assign({ variant: "primary", size: "large", fullWidth: true, disabled: isSubmitButtonDisabled }, { children: "\uC218\uC815" }), void 0)] }, void 0)] }), void 0));
};
export default ProfileEditForm;
