var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { postGuestReservation, postMemberGuestReservation, } from 'api/guestReservation';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import ColorDot from 'components/ColorDot/ColorDot';
import Input from 'components/Input/Input';
import Select from 'components/Select/Select';
import TimePicker from 'components/TimePicker/TimePicker';
import DATE from 'constants/date';
import MESSAGE from 'constants/message';
import { HREF } from 'constants/path';
import RESERVATION from 'constants/reservation';
import SPACE from 'constants/space';
import useMember from 'hooks/query/useMember';
import { AccessTokenContext } from 'providers/AccessTokenProvider';
import { formatTimePrettier, formatTimeWithSecond, isPastDate } from 'utils/datetime';
import { isNullish } from 'utils/type';
import { GuestMapFormContext } from '../providers/GuestMapFormProvider';
import * as Styled from './ReservationForm.styled';
var ReservationForm = function (_a) {
    var _b;
    var map = _a.map;
    var history = useHistory();
    var accessToken = useContext(AccessTokenContext).accessToken;
    var _c = useContext(GuestMapFormContext), formValues = _c.formValues, onChangeFormValues = _c.onChangeFormValues, selectedSpaceId = _c.selectedSpaceId, setSelectedSpaceId = _c.setSelectedSpaceId, timePicker = _c.timePicker, availableSpaceList = _c.availableSpaceList, spacesMap = _c.spacesMap;
    var member = useMember({
        enabled: !!accessToken,
    });
    var userName = (_b = member.data) === null || _b === void 0 ? void 0 : _b.data.userName;
    var getSpaceOptions = function () {
        var _a;
        return ((_a = availableSpaceList === null || availableSpaceList === void 0 ? void 0 : availableSpaceList.map(function (space) { return ({
            value: "" + space.id,
            children: (_jsxs(Styled.SpaceOption, { children: [_jsx(ColorDot, { size: "medium", color: space.color }, void 0), space.name] }, void 0)),
        }); })) !== null && _a !== void 0 ? _a : []);
    };
    var onSuccessCreateReservation = function (_, _a) {
        var reservation = _a.reservation;
        if (!(spacesMap === null || spacesMap === void 0 ? void 0 : spacesMap[Number(selectedSpaceId)]))
            return;
        var startDateTime = reservation.startDateTime, endDateTime = reservation.endDateTime, description = reservation.description;
        history.push({
            pathname: HREF.GUEST_RESERVATION_SUCCESS(map.sharingMapId),
            state: {
                space: spacesMap[Number(selectedSpaceId)],
                reservation: {
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    name: userName !== null && userName !== void 0 ? userName : '',
                    description: description,
                },
                targetDate: dayjs(formValues.date).tz(),
            },
        });
    };
    var addMemberGuestReservation = useMutation(postMemberGuestReservation, {
        onSuccess: onSuccessCreateReservation,
        onError: function (error) {
            var _a, _b;
            alert((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message) !== null && _b !== void 0 ? _b : MESSAGE.RESERVATION.UNEXPECTED_ERROR);
        },
    });
    var addGuestReservation = useMutation(postGuestReservation, {
        onSuccess: onSuccessCreateReservation,
        onError: function (error) {
            var _a, _b;
            alert((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message) !== null && _b !== void 0 ? _b : MESSAGE.RESERVATION.UNEXPECTED_ERROR);
        },
    });
    var handleSubmit = function (event) {
        event.preventDefault();
        if ((timePicker === null || timePicker === void 0 ? void 0 : timePicker.range.start) == null || (timePicker === null || timePicker === void 0 ? void 0 : timePicker.range.end) == null)
            return;
        var startDateTime = formValues.date + "T" + formatTimeWithSecond(timePicker.range.start) + DATE.TIMEZONE_OFFSET;
        var endDateTime = formValues.date + "T" + formatTimeWithSecond(timePicker.range.end) + DATE.TIMEZONE_OFFSET;
        accessToken
            ? addMemberGuestReservation.mutate({
                reservation: {
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    description: formValues.description,
                },
                mapId: map.mapId,
                spaceId: Number(selectedSpaceId),
            })
            : addGuestReservation.mutate({
                reservation: {
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    description: formValues.description,
                    name: formValues.name,
                    password: formValues.password,
                },
                mapId: map.mapId,
                spaceId: Number(selectedSpaceId),
            });
    };
    return (_jsxs(Styled.ReservationForm, __assign({ onSubmit: handleSubmit }, { children: [_jsxs(Styled.Section, { children: [_jsx(Styled.InputWrapper, { children: _jsx(Input, { type: "date", name: "date", label: "\uB0A0\uC9DC", icon: _jsx(CalendarIcon, {}, void 0), value: formValues.date, min: DATE.MIN_DATE_STRING, max: DATE.MAX_DATE_STRING, onChange: onChangeFormValues, required: true }, void 0) }, void 0), _jsxs(Styled.InputWrapper, { children: [timePicker && (_jsx(TimePicker, { label: "\uC608\uC57D\uC2DC\uAC04", range: timePicker.range, step: SPACE.RESERVATION.MIN_STEP, selectedTime: timePicker.selectedTime, onClick: timePicker.onClick, onChange: timePicker.onChange, onCloseOptions: timePicker.onCloseOptions }, void 0)), (spacesMap === null || spacesMap === void 0 ? void 0 : spacesMap[Number(selectedSpaceId)]) && (_jsxs(Styled.TimeFormMessageWrapper, { children: [_jsx(Styled.TimeFormMessage, __assign({ fontWeight: "bold" }, { children: "\uC608\uC57D \uAC00\uB2A5 \uC2DC\uAC04" }), void 0), _jsxs(Styled.TimeFormMessageList, { children: [spacesMap[Number(selectedSpaceId)].settings.map(function (_a, index) {
                                                var settingStartTime = _a.settingStartTime, settingEndTime = _a.settingEndTime, reservationMaximumTimeUnit = _a.reservationMaximumTimeUnit, reservationMinimumTimeUnit = _a.reservationMinimumTimeUnit;
                                                return (_jsxs(Styled.TimeFormMessage, { children: [settingStartTime.slice(0, 5), " ~ ", settingEndTime.slice(0, 5), "(\uCD5C\uC18C ", formatTimePrettier(reservationMinimumTimeUnit), ", \uCD5C\uB300", ' ', formatTimePrettier(reservationMaximumTimeUnit), ")"] }, index));
                                            }), ' '] }, void 0)] }, void 0))] }, void 0), _jsx(Styled.InputWrapper, { children: _jsx(Select, { name: "space", label: "\uACF5\uAC04 \uC120\uD0DD", options: getSpaceOptions(), value: selectedSpaceId, onChange: function (id) { return setSelectedSpaceId === null || setSelectedSpaceId === void 0 ? void 0 : setSelectedSpaceId(id); } }, void 0) }, void 0), _jsxs(Styled.InputsRow, { children: [_jsx(Styled.InputWrapper, { children: _jsx(Input, { label: "\uC774\uB984", name: "name", value: userName !== null && userName !== void 0 ? userName : formValues.name, onChange: onChangeFormValues, disabled: !isNullish(accessToken) }, void 0) }, void 0), accessToken == null && (_jsx(Styled.InputWrapper, { children: _jsx(Input, { label: "\uBE44\uBC00\uBC88\uD638", type: "password", name: "password", value: formValues.password, onChange: onChangeFormValues }, void 0) }, void 0))] }, void 0), _jsx(Styled.InputWrapper, { children: _jsx(Input, { label: "\uC0AC\uC6A9 \uBAA9\uC801", name: "description", value: formValues.description, onChange: onChangeFormValues, maxLength: RESERVATION.DESCRIPTION.MAX_LENGTH, required: true }, void 0) }, void 0)] }, void 0), _jsx(Styled.ButtonWrapper, { children: _jsx(Styled.ReservationButton, __assign({ fullWidth: true, variant: "primary", size: "large", disabled: isPastDate(new Date(formValues.date)) }, { children: MESSAGE.RESERVATION.CREATE }), void 0) }, void 0)] }), void 0));
};
export default ReservationForm;
