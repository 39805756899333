var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Z_INDEX } from 'constants/style';
export var Overlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", ";\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: ", ";\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n"], ["\n  display: ", ";\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: ", ";\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n"])), function (_a) {
    var open = _a.open;
    return (open ? 'flex' : 'none');
}, Z_INDEX.MODAL_OVERLAY, function (_a) {
    var theme = _a.theme;
    return theme.modalOverlay;
});
export var Modal = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 80%;\n  min-width: 320px;\n  max-width: 768px;\n  position: relative;\n  overflow: hidden;\n  border-radius: 4px;\n  background-color: ", ";\n"], ["\n  width: 80%;\n  min-width: 320px;\n  max-width: 768px;\n  position: relative;\n  overflow: hidden;\n  border-radius: 4px;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.white;
});
export var CloseButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  padding: 0.5rem;\n  top: 0.5rem;\n  right: 1.5rem;\n  width: 1rem;\n  cursor: pointer;\n  border: none;\n  background: none;\n\n  &:hover {\n    opacity: 0.7;\n  }\n"], ["\n  position: absolute;\n  padding: 0.5rem;\n  top: 0.5rem;\n  right: 1.5rem;\n  width: 1rem;\n  cursor: pointer;\n  border: none;\n  background: none;\n\n  &:hover {\n    opacity: 0.7;\n  }\n"])));
export var Inner = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0.5rem 1rem;\n"], ["\n  padding: 0.5rem 1rem;\n"])));
export var Header = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 700;\n  padding: 1rem;\n  margin-bottom: 0.5rem;\n"], ["\n  font-weight: 700;\n  padding: 1rem;\n  margin-bottom: 0.5rem;\n"])));
export var Content = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
