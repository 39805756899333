var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import GrayLogoImage from 'assets/images/gray-logo.png';
import Header from 'components/Header/Header';
import Layout from 'components/Layout/Layout';
import PATH from 'constants/path';
import * as Styled from './NotFound.styles';
var Main = function () {
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}, void 0), _jsx(Layout, { children: _jsxs(Styled.Container, { children: [_jsx(Styled.Image, { src: GrayLogoImage, alt: "Not Found" }, void 0), _jsx(Styled.PageHeader, { children: "\uD574\uB2F9 \uD398\uC774\uC9C0\uB97C \uCC3E\uC9C0 \uBABB\uD588\uC2B5\uB2C8\uB2E4." }, void 0), _jsx(Styled.HomeLink, __assign({ to: PATH.MAIN }, { children: "\uD648\uC73C\uB85C \uC774\uB3D9\uD558\uAE30" }), void 0)] }, void 0) }, void 0)] }, void 0));
};
export default Main;
