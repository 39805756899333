import THROW_ERROR from 'constants/throwError';
export var getLocalStorageItem = function (_a) {
    var key = _a.key, defaultValue = _a.defaultValue;
    var storedData = localStorage.getItem(key);
    if (!storedData) {
        return defaultValue;
    }
    try {
        return JSON.parse(storedData);
    }
    catch (_b) {
        throw new Error(THROW_ERROR.NOT_JSON_FORMAT);
    }
};
var getCircularReplacer = function () {
    var seen = new WeakSet();
    return function (key, value) {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};
export var setLocalStorageItem = function (_a) {
    var key = _a.key, item = _a.item;
    var data = JSON.stringify(item, getCircularReplacer());
    if (data === undefined) {
        throw new Error(THROW_ERROR.NOT_MATCHED_JSON);
    }
    localStorage.setItem(key, data);
};
export var removeLocalStorageItem = function (_a) {
    var key = _a.key;
    localStorage.removeItem(key);
};
