var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n"])));
export var Button = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: none;\n  border-radius: 50%;\n  background-color: transparent;\n  padding: 0;\n  cursor: pointer;\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"], ["\n  border: none;\n  border-radius: 50%;\n  background-color: transparent;\n  padding: 0;\n  cursor: pointer;\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"])));
var iconSizeCSS = {
    small: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 1.25rem;\n    height: 1.25rem;\n  "], ["\n    width: 1.25rem;\n    height: 1.25rem;\n  "]))),
    medium: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 1.5rem;\n    height: 1.5rem;\n  "], ["\n    width: 1.5rem;\n    height: 1.5rem;\n  "]))),
    large: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 2.25rem;\n    height: 2.25rem;\n  "], ["\n    width: 2.25rem;\n    height: 2.25rem;\n  "]))),
    xLarge: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    width: 2.75rem;\n    height: 2.75rem;\n  "], ["\n    width: 2.75rem;\n    height: 2.75rem;\n  "]))),
};
export var IconWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  overflow: hidden;\n"], ["\n  ", "\n  overflow: hidden;\n"])), function (_a) {
    var size = _a.size;
    return iconSizeCSS[size];
});
var textSizeCSS = {
    small: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    font-size: 0.5rem;\n  "], ["\n    font-size: 0.5rem;\n  "]))),
    medium: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font-size: 0.5rem;\n  "], ["\n    font-size: 0.5rem;\n  "]))),
    large: css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    font-size: 0.75rem;\n  "], ["\n    font-size: 0.75rem;\n  "]))),
    xLarge: css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    font-size: 0.875rem;\n  "], ["\n    font-size: 0.875rem;\n  "]))),
};
export var Text = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", "\n  user-select: none;\n  white-space: nowrap;\n  text-align: center;\n  margin-top: 0.125rem;\n"], ["\n  ", "\n  user-select: none;\n  white-space: nowrap;\n  text-align: center;\n  margin-top: 0.125rem;\n"])), function (_a) {
    var size = _a.size;
    return textSizeCSS[size];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
