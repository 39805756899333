import api from './api';
export var postLogin = function (loginData) {
    return api.post('/members/login/token', loginData);
};
export var postTokenValidation = function () {
    return api.post('/members/token');
};
export var queryGithubLogin = function (_a) {
    var queryKey = _a.queryKey;
    var code = queryKey[1].code;
    return api.get("/members/github/login/token?code=" + code);
};
export var queryGoogleLogin = function (_a) {
    var queryKey = _a.queryKey;
    var code = queryKey[1].code;
    return api.get("/members/google/login/token?code=" + code);
};
