var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n"])));
var selectedCSS = {
    default: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.gray[200];
    }, function (_a) {
        var theme = _a.theme;
        return theme.gray[400];
    }),
    selected: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.white;
    }, function (_a) {
        var theme = _a.theme;
        return theme.primary[500];
    }),
};
export var TabItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 1.375rem 2.375rem;\n  font-weight: bold;\n  border-radius: 1.125rem 1.125rem 0 0;\n  cursor: pointer;\n\n  ", "\n"], ["\n  padding: 1.375rem 2.375rem;\n  font-weight: bold;\n  border-radius: 1.125rem 1.125rem 0 0;\n  cursor: pointer;\n\n  ", "\n"])), function (_a) {
    var selected = _a.selected;
    return (selected ? selectedCSS.selected : selectedCSS.default);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
