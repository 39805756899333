var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { putMemberPassword } from 'api/member';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Input from 'components/Input/Input';
import Layout from 'components/Layout/Layout';
import MANAGER from 'constants/manager';
import MESSAGE from 'constants/message';
import PATH from 'constants/path';
import REGEXP from 'constants/regexp';
import useInputs from 'hooks/useInputs';
import * as Styled from './ManagerPasswordEdit.styles';
var ManagerPasswordEdit = function () {
    var history = useHistory();
    var _a = useInputs({
        prevPassword: '',
        password: '',
        passwordConfirm: '',
    }), _b = _a[0], prevPassword = _b.prevPassword, password = _b.password, passwordConfirm = _b.passwordConfirm, onChangeForm = _a[1];
    var _c = useState(''), passwordMessage = _c[0], setPasswordMessage = _c[1];
    var _d = useState(''), passwordConfirmMessage = _d[0], setPasswordConfirmMessage = _d[1];
    var editPassword = useMutation(putMemberPassword, {
        onSuccess: function () {
            history.push(PATH.MANAGER_MAP_LIST);
        },
        onError: function (error) {
            var _a, _b;
            alert((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data.message) !== null && _b !== void 0 ? _b : MESSAGE.MEMBER.EDIT_PROFILE_UNEXPECTED_ERROR);
        },
    });
    var handleSubmit = function (e) {
        e.preventDefault();
        editPassword.mutate({
            newPassword: password,
            newPasswordConfirm: passwordConfirm,
            oldPassword: prevPassword,
        });
    };
    var isValidPassword = REGEXP.PASSWORD.test(password);
    var isValidPasswordConfirm = password === passwordConfirm;
    var isSubmitButtonDisabled = !(prevPassword &&
        password &&
        passwordConfirm &&
        isValidPassword &&
        isValidPasswordConfirm);
    var handleCancel = function () {
        history.push(PATH.MANAGER_PROFILE_EDIT);
    };
    useEffect(function () {
        if (!password)
            return;
        setPasswordMessage(isValidPassword ? MESSAGE.JOIN.VALID_PASSWORD : MESSAGE.JOIN.INVALID_PASSWORD);
    }, [password, isValidPassword]);
    useEffect(function () {
        if (!password || !passwordConfirm)
            return;
        setPasswordConfirmMessage(password === passwordConfirm
            ? MESSAGE.JOIN.VALID_PASSWORD_CONFIRM
            : MESSAGE.JOIN.INVALID_PASSWORD_CONFIRM);
    }, [password, passwordConfirm]);
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}, void 0), _jsx(Layout, { children: _jsxs(Styled.ContainerForm, __assign({ onSubmit: handleSubmit }, { children: [_jsx(Styled.PageTitle, { children: "\uB0B4 \uBE44\uBC00\uBC88\uD638 \uC218\uC815" }, void 0), _jsx(Styled.InputWrapper, { children: _jsx(Input, { type: "password", label: "\uC774\uC804 \uBE44\uBC00\uBC88\uD638", name: "prevPassword", minLength: MANAGER.PASSWORD.MIN_LENGTH, maxLength: MANAGER.PASSWORD.MAX_LENGTH, value: prevPassword, onChange: onChangeForm, required: true }, void 0) }, void 0), _jsx(Styled.InputWrapper, { children: _jsx(Input, { type: "password", label: "\uC2E0\uADDC \uBE44\uBC00\uBC88\uD638", name: "password", minLength: MANAGER.PASSWORD.MIN_LENGTH, maxLength: MANAGER.PASSWORD.MAX_LENGTH, value: password, onChange: onChangeForm, message: passwordMessage, status: isValidPassword ? 'success' : 'error', required: true }, void 0) }, void 0), _jsx(Styled.InputWrapper, { children: _jsx(Input, { type: "password", label: "\uC2E0\uADDC \uBE44\uBC00\uBC88\uD638 \uD655\uC778", name: "passwordConfirm", minLength: MANAGER.PASSWORD.MIN_LENGTH, maxLength: MANAGER.PASSWORD.MAX_LENGTH, value: passwordConfirm, onChange: onChangeForm, message: passwordConfirmMessage, status: isValidPasswordConfirm ? 'success' : 'error', required: true }, void 0) }, void 0), _jsxs(Styled.ButtonContainer, { children: [_jsx(Button, __assign({ size: "large", type: "button", fullWidth: true, onClick: handleCancel }, { children: "\uCDE8\uC18C" }), void 0), _jsx(Button, __assign({ variant: "primary", size: "large", fullWidth: true, disabled: isSubmitButtonDisabled }, { children: "\uC218\uC815" }), void 0)] }, void 0)] }), void 0) }, void 0)] }, void 0));
};
export default ManagerPasswordEdit;
