var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Z_INDEX } from 'constants/style';
export var Select = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var Label = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
export var ListBoxButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 0.5rem;\n  border: 1px solid ", ";\n  border-radius: 0.25rem;\n  background: ", ";\n  padding: 0.75rem;\n  width: 100%;\n  font-size: 1rem;\n  text-align: left;\n  z-index: ", ";\n  cursor: pointer;\n\n  &:focus {\n    border-color: ", ";\n    box-shadow: inset 0px 0px 0px 1px ", ";\n  }\n\n  &:disabled {\n    background-color: ", ";\n    cursor: default;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 0.5rem;\n  border: 1px solid ", ";\n  border-radius: 0.25rem;\n  background: ", ";\n  padding: 0.75rem;\n  width: 100%;\n  font-size: 1rem;\n  text-align: left;\n  z-index: ", ";\n  cursor: pointer;\n\n  &:focus {\n    border-color: ", ";\n    box-shadow: inset 0px 0px 0px 1px ", ";\n  }\n\n  &:disabled {\n    background-color: ", ";\n    cursor: default;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
}, function (_a) {
    var theme = _a.theme;
    return theme.white;
}, Z_INDEX.SELECT_LIST_BOX_BUTTON, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.gray[100];
});
export var ListBoxLabel = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
});
export var OptionChildrenWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
export var CaretDownIconWrapper = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-flex;\n  justify-content: space-between;\n  align-items: center;\n  transform: ", ";\n  transition: transform 0.2s ease;\n"], ["\n  display: inline-flex;\n  justify-content: space-between;\n  align-items: center;\n  transform: ", ";\n  transition: transform 0.2s ease;\n"])), function (_a) {
    var open = _a.open;
    return (open ? 'matrix(1, 0, 0, -1, 0, 0)' : 'matrix(1, 0, 0, 1, 0, 0)');
});
export var ListBox = styled.ul(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 100%;\n  max-height: ", ";\n  border: 1px solid ", ";\n  border-bottom-left-radius: 0.25rem;\n  border-bottom-right-radius: 0.25rem;\n  transform: translateY(-0.125rem);\n  overflow-y: auto;\n  z-index: ", ";\n\n  &::before {\n    position: relative;\n    display: block;\n    content: '';\n    height: 0.125rem;\n  }\n"], ["\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 100%;\n  max-height: ", ";\n  border: 1px solid ", ";\n  border-bottom-left-radius: 0.25rem;\n  border-bottom-right-radius: 0.25rem;\n  transform: translateY(-0.125rem);\n  overflow-y: auto;\n  z-index: ", ";\n\n  &::before {\n    position: relative;\n    display: block;\n    content: '';\n    height: 0.125rem;\n  }\n"])), function (_a) {
    var maxheight = _a.maxheight;
    if (!maxheight)
        return '15rem';
    if (typeof maxheight === 'number')
        return maxheight + "px";
    return maxheight;
}, function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
}, Z_INDEX.SELECT_LIST_BOX);
export var Option = styled.li(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  background: ", ";\n  padding: 0.75rem;\n  line-height: normal;\n  cursor: pointer;\n\n  &:last-of-type {\n    border-bottom: 0;\n  }\n\n  &:hover,\n  &:focus {\n    background: ", ";\n  }\n"], ["\n  border-bottom: 1px solid ", ";\n  background: ", ";\n  padding: 0.75rem;\n  line-height: normal;\n  cursor: pointer;\n\n  &:last-of-type {\n    border-bottom: 0;\n  }\n\n  &:hover,\n  &:focus {\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.gray[100];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
