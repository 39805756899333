var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { Midday } from 'types/time';
import * as Styled from './TimePickerOptions.styles';
var TimePickerOptions = function (_a) {
    var time = _a.time, _b = _a.step, step = _b === void 0 ? 1 : _b, onChange = _a.onChange;
    var middayRef = useRef(null);
    var hourRef = useRef(null);
    var minuteRef = useRef(null);
    var optionRef = useRef(null);
    useEffect(function () {
        if (!time || !optionRef.current)
            return;
        var optionHeight = optionRef.current.offsetHeight;
        if (middayRef.current) {
            middayRef.current.scrollTo(0, time.midday === Midday.AM ? 0 : optionHeight);
        }
        if (hourRef.current) {
            hourRef.current.scrollTo(0, (time.hour - 1) * optionHeight);
        }
        if (minuteRef.current) {
            minuteRef.current.scrollTo(0, (time.minute / step) * optionHeight);
        }
    }, [step, middayRef, hourRef, minuteRef, time]);
    return (_jsxs(Styled.OptionsContainer, { children: [_jsxs(Styled.OptionContainer, __assign({ ref: middayRef }, { children: [_jsxs(Styled.Option, __assign({ ref: optionRef }, { children: [_jsx(Styled.Radio, { type: "radio", name: "midday", value: "\uC624\uC804", onChange: onChange('midday'), checked: (time === null || time === void 0 ? void 0 : time.midday) === Midday.AM, tabIndex: 0 }, void 0), _jsx(Styled.OptionText, { children: "\uC624\uC804" }, void 0)] }), void 0), _jsxs(Styled.Option, { children: [_jsx(Styled.Radio, { type: "radio", name: "midday", value: "\uC624\uD6C4", onChange: onChange('midday'), checked: (time === null || time === void 0 ? void 0 : time.midday) === Midday.PM, tabIndex: 0 }, void 0), _jsx(Styled.OptionText, { children: "\uC624\uD6C4" }, void 0)] }, void 0)] }), void 0), _jsx(Styled.OptionContainer, __assign({ ref: hourRef }, { children: Array.from({ length: 12 }).map(function (_, index) { return (_jsxs(Styled.Option, { children: [_jsx(Styled.Radio, { type: "radio", name: "hour", value: index + 1, onChange: onChange('hour'), checked: index + 1 === (time === null || time === void 0 ? void 0 : time.hour), tabIndex: 0 }, void 0), _jsxs(Styled.OptionText, { children: [String(index + 1).padStart(2, '0'), " \uC2DC"] }, void 0)] }, index + "-hour")); }) }), void 0), _jsx(Styled.OptionContainer, __assign({ ref: minuteRef }, { children: Array.from({ length: 60 / step }).map(function (_, index) { return (_jsxs(Styled.Option, { children: [_jsx(Styled.Radio, { type: "radio", name: "minute", value: index * step, onChange: onChange('minute'), checked: index * step === (time === null || time === void 0 ? void 0 : time.minute), tabIndex: 0 }, void 0), _jsxs(Styled.OptionText, { children: [String(index * step).padStart(2, '0'), " \uBD84"] }, void 0)] }, index + "-minute")); }) }), void 0)] }, void 0));
};
export default TimePickerOptions;
