var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useMemo, useState } from 'react';
import DATE from 'constants/date';
import SPACE from 'constants/space';
import useGuestSpaceAvailable from 'hooks/query/useGuestSpaceAvailable';
import useGuestSpaces from 'hooks/query/useGuestSpaces';
import useInputs from 'hooks/useInputs';
import useTimePicker from 'hooks/useTimePicker';
import { formatDate, formatTimeWithSecond } from 'utils/datetime';
var convertTimeToDate = function (date, time) {
    if (time === null) {
        return null;
    }
    return date + "T" + formatTimeWithSecond(time) + DATE.TIMEZONE_OFFSET;
};
var initialValue = {
    formValues: {
        description: '',
        password: '',
        date: formatDate(new Date()),
        name: '',
    },
    selectedSpaceId: '',
};
export var GuestMapFormContext = createContext(initialValue);
var GuestMapFormProvider = function (_a) {
    var _b, _c, _d, _e, _f;
    var mapId = _a.mapId, children = _a.children;
    var _g = useInputs(initialValue.formValues), formValues = _g[0], onChangeFormValues = _g[1];
    var _h = useState(''), selectedSpaceId = _h[0], setSelectedSpaceId = _h[1];
    var timePicker = useTimePicker({
        step: SPACE.RESERVATION.MIN_STEP,
        initialStartTime: undefined,
        initialEndTime: undefined,
    });
    var getSpaces = useGuestSpaces({ mapId: mapId });
    var spacesMap = useMemo(function () {
        var _a;
        if (!((_a = getSpaces.data) === null || _a === void 0 ? void 0 : _a.data)) {
            return {};
        }
        return getSpaces.data.data.spaces.reduce(function (prev, cur) {
            prev[cur.id] = cur;
            return prev;
        }, {});
    }, [(_b = getSpaces.data) === null || _b === void 0 ? void 0 : _b.data]);
    var getSpaceAvailable = useGuestSpaceAvailable({
        mapId: mapId,
        startDateTime: convertTimeToDate(formValues.date, timePicker.range.start),
        endDateTime: convertTimeToDate(formValues.date, timePicker.range.end),
    }, { enabled: !!timePicker.range.start && !!timePicker.range.end });
    var spaceList = (_d = (_c = getSpaces.data) === null || _c === void 0 ? void 0 : _c.data.spaces) !== null && _d !== void 0 ? _d : [];
    var availableSpaceList = (_f = (_e = getSpaceAvailable.data) === null || _e === void 0 ? void 0 : _e.data.spaces.filter(function (space) { return space.isAvailable; }).map(function (space) { return spacesMap[space.spaceId]; })) !== null && _f !== void 0 ? _f : [];
    var value = {
        formValues: formValues,
        onChangeFormValues: onChangeFormValues,
        selectedSpaceId: selectedSpaceId,
        setSelectedSpaceId: setSelectedSpaceId,
        timePicker: timePicker,
        spaceList: spaceList,
        availableSpaceList: availableSpaceList,
        spacesMap: spacesMap,
    };
    return _jsx(GuestMapFormContext.Provider, __assign({ value: value }, { children: children }), void 0);
};
export default GuestMapFormProvider;
