var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Button/Button';
import Layout from 'components/Layout/Layout';
import { Z_INDEX } from 'constants/style';
export var Header = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  height: 3rem;\n  background-color: ", ";\n  box-shadow: 0 0.25rem 0.25rem 0 ", ";\n  z-index: ", ";\n"], ["\n  position: sticky;\n  top: 0;\n  height: 3rem;\n  background-color: ", ";\n  box-shadow: 0 0.25rem 0.25rem 0 ", ";\n  z-index: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.shadow;
}, Z_INDEX.HEADER);
export var HeaderLayout = styled(Layout)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 100%;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 100%;\n"])));
export var HeaderLink = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  color: ", ";\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  color: ", ";\n  cursor: pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.black[400];
});
export var ButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var TextButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 0.75rem;\n  padding: 0.625rem 0.5rem;\n"], ["\n  font-size: 0.75rem;\n  padding: 0.625rem 0.5rem;\n"])));
export var TextLink = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: none;\n  font-size: 0.75rem;\n  padding: 0.625rem 0.5rem;\n"], ["\n  color: ", ";\n  text-decoration: none;\n  font-size: 0.75rem;\n  padding: 0.625rem 0.5rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.black[400];
});
export var Logo = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 1.75rem;\n"], ["\n  width: 1.75rem;\n"])));
export var Title = styled.h1(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 1.5rem;\n  font-weight: 700;\n  margin-left: 0.5rem;\n  vertical-align: middle;\n  transform: translateY(-0.125rem);\n"], ["\n  font-size: 1.5rem;\n  font-weight: 700;\n  margin-left: 0.5rem;\n  vertical-align: middle;\n  transform: translateY(-0.125rem);\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
