import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Switch from 'components/Switch/Switch';
import { SWITCH_LABEL_LIST } from '../GuestMap';
import * as Styled from './Aside.styled';
import ReservationForm from './ReservationForm';
import ReservationList from './ReservationList';
var Aside = function (_a) {
    var _b;
    var map = _a.map, selectedLabel = _a.selectedLabel, onClickSwitch = _a.onClickSwitch, selectedSpaceId = _a.selectedSpaceId, onDelete = _a.onDelete, onEdit = _a.onEdit;
    var isReservation = selectedLabel === SWITCH_LABEL_LIST[0];
    return (_jsxs(Styled.Container, { children: [map.notice && (_jsxs(Styled.Notice, { children: [_jsx(Styled.NoticeTitle, { children: "\uACF5\uC9C0\uC0AC\uD56D" }, void 0), _jsx(Styled.NoticeText, { children: (_b = map === null || map === void 0 ? void 0 : map.notice) !== null && _b !== void 0 ? _b : '' }, void 0)] }, void 0)), _jsx(Styled.SwitchContainer, { children: _jsx(Switch, { labelList: SWITCH_LABEL_LIST, selectedLabel: selectedLabel, onClick: onClickSwitch }, void 0) }, void 0), isReservation ? (_jsx(ReservationForm, { map: map }, void 0)) : (_jsx(ReservationList, { map: map, 
                // selectedSpaceId={selectedSpaceId}
                onEdit: onEdit, onDelete: onDelete }, void 0))] }, void 0));
};
export default Aside;
