var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import Button from 'components/Button/Button';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 60px 0 80px;\n"], ["\n  padding: 60px 0 80px;\n"])));
export var ListTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  font-size: 1.25rem;\n  font-weight: bold;\n"], ["\n  display: flex;\n  align-items: center;\n  font-size: 1.25rem;\n  font-weight: bold;\n"])));
export var List = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 28px 0 0;\n"], ["\n  margin: 28px 0 0;\n"])));
export var ButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 36px 0;\n  display: flex;\n  justify-content: center;\n"], ["\n  margin: 36px 0;\n  display: flex;\n  justify-content: center;\n"])));
export var RoundedButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  padding: 12px 48px;\n  font-size: 1.125rem;\n\n  :hover {\n    background-color: ", ";\n  }\n"], ["\n  border: 1px solid ", ";\n  padding: 12px 48px;\n  font-size: 1.125rem;\n\n  :hover {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.gray[100];
});
export var FlexLeft = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
export var HistoryListContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 40px 0;\n"], ["\n  margin: 40px 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
