var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs, { isDayjs } from 'dayjs';
import { useState } from 'react';
import { Midday } from 'types/time';
var generateTo12Hour = function (hour) {
    var result = hour > 12 ? hour % 12 : hour;
    return result === 0 ? 12 : result;
};
var generateDateToTime = function (time, step) {
    if (step === void 0) { step = 1; }
    var minute = isDayjs(time)
        ? Math.ceil(time.minute() / step) * step
        : Math.ceil(time.getMinutes() / step) * step;
    var hour = (function () {
        if (isDayjs(time)) {
            return minute < 60 ? time.hour() : time.hour() + 1;
        }
        return minute < 60 ? time.getHours() : time.getHours() + 1;
    })();
    var midday = hour < 12 ? Midday.AM : Midday.PM;
    return {
        midday: midday,
        hour: generateTo12Hour(hour),
        minute: minute % 60,
    };
};
var useTimePicker = function (_a) {
    var initialStartTime = _a.initialStartTime, initialEndTime = _a.initialEndTime, _b = _a.step, step = _b === void 0 ? 1 : _b;
    var _c = useState(null), selectedTime = _c[0], setSelectedTime = _c[1];
    var _d = useState({
        start: initialStartTime ? generateDateToTime(dayjs(initialStartTime).tz(), step) : null,
        end: initialEndTime ? generateDateToTime(dayjs(initialEndTime).tz(), step) : null,
    }), range = _d[0], setRange = _d[1];
    var setInitialTime = function (key) {
        if (key === 'start' || (key === 'end' && range.start === null)) {
            var now_1 = dayjs().tz();
            setRange(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[key] = generateDateToTime(now_1, step), _a)));
            });
        }
        if (key === 'end' && range.start !== null) {
            var startTime_1 = range.start;
            var endMidday_1 = startTime_1.hour !== 11
                ? startTime_1.midday
                : startTime_1.midday === Midday.AM
                    ? Midday.PM
                    : Midday.AM;
            setRange(function (prev) { return (__assign(__assign({}, prev), { end: {
                    midday: endMidday_1,
                    hour: generateTo12Hour(startTime_1.hour + 1),
                    minute: startTime_1.minute,
                } })); });
        }
    };
    var onClickTimeButton = function (_a) {
        var currentTarget = _a.currentTarget;
        var key = currentTarget.name;
        if (selectedTime === key) {
            setSelectedTime(null);
            return;
        }
        if (range[key] === null) {
            setInitialTime(key);
        }
        setSelectedTime(key);
    };
    var onChange = function (name) {
        return function (_a) {
            var target = _a.target;
            if (selectedTime === null)
                return;
            var value = name === 'midday' ? target.value : Number(target.value);
            setRange(function (prev) {
                var _a, _b;
                return (__assign(__assign({}, prev), (_a = {}, _a[selectedTime] = __assign(__assign({}, prev[selectedTime]), (_b = {}, _b[name] = value, _b)), _a)));
            });
        };
    };
    var onCloseOptions = function () {
        setSelectedTime(null);
    };
    return { range: range, selectedTime: selectedTime, onClick: onClickTimeButton, onChange: onChange, onCloseOptions: onCloseOptions };
};
export default useTimePicker;
