var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import PALETTE from 'constants/palette';
var providerCSS = {
    GITHUB: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n    border: none;\n  "], ["\n    background-color: ", ";\n    color: ", ";\n    border: none;\n  "])), PALETTE.GITHUB, PALETTE.WHITE),
    GOOGLE: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n    border: 1px solid ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n    border: 1px solid ", ";\n  "])), PALETTE.WHITE, PALETTE.BLACK[700], PALETTE.BLACK[700]),
};
var buttonCSS = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 1rem;\n  text-decoration: none;\n  padding: 0.75rem 1rem;\n  font-size: 1.25rem;\n  border-radius: 0.125rem;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 1rem;\n  text-decoration: none;\n  padding: 0.75rem 1rem;\n  font-size: 1.25rem;\n  border-radius: 0.125rem;\n"])));
export var SocialLoginButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  ", ";\n  width: ", ";\n  height: 52px;\n  cursor: pointer;\n"], ["\n  ", "\n  ", ";\n  width: ", ";\n  height: 52px;\n  cursor: pointer;\n"])), function (_a) {
    var provider = _a.provider;
    return providerCSS[provider];
}, buttonCSS, function (_a) {
    var variant = _a.variant;
    return (variant === 'icon' ? '52px' : '100%');
});
export var SocialJoinButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n  ", ";\n  cursor: pointer;\n"], ["\n  ", "\n  ", ";\n  cursor: pointer;\n"])), function (_a) {
    var provider = _a.provider;
    return providerCSS[provider];
}, buttonCSS);
export var Icon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  justify-content: flex-end;\n"])));
export var Text = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
