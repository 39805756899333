var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext } from 'react';
import { LOCAL_STORAGE_KEY } from 'constants/storage';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem, } from 'utils/localStorage';
export var AccessTokenContext = createContext({
    accessToken: null,
    setAccessToken: function (token) { return undefined; },
    resetAccessToken: function () { return undefined; },
});
var AccessTokenProvider = function (_a) {
    var children = _a.children;
    var _b = useState(getLocalStorageItem({ key: LOCAL_STORAGE_KEY.ACCESS_TOKEN, defaultValue: null })), token = _b[0], setToken = _b[1];
    var setAccessToken = function (accessToken) {
        setToken(accessToken);
        setLocalStorageItem({ key: LOCAL_STORAGE_KEY.ACCESS_TOKEN, item: accessToken });
    };
    var resetAccessToken = function () {
        setToken(null);
        removeLocalStorageItem({ key: LOCAL_STORAGE_KEY.ACCESS_TOKEN });
    };
    return (_jsx(AccessTokenContext.Provider, __assign({ value: { accessToken: token, setAccessToken: setAccessToken, resetAccessToken: resetAccessToken } }, { children: children }), void 0));
};
export default AccessTokenProvider;
