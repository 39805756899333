var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import PALETTE from 'constants/palette';
export var OptionsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  border: 1px solid ", ";\n  border-top: 0;\n  border-radius: 0 0 0.125rem 0.125rem;\n  display: flex;\n  align-items: center;\n  height: 10rem;\n  overflow: hidden;\n  position: relative;\n  background-color: ", ";\n\n  &::before {\n    content: '';\n    display: block;\n    width: inherit;\n    height: 2rem;\n    background-color: ", ";\n    position: absolute;\n    top: calc(4rem + 0.5px);\n    pointer-events: none;\n  }\n"], ["\n  width: 100%;\n  border: 1px solid ", ";\n  border-top: 0;\n  border-radius: 0 0 0.125rem 0.125rem;\n  display: flex;\n  align-items: center;\n  height: 10rem;\n  overflow: hidden;\n  position: relative;\n  background-color: ", ";\n\n  &::before {\n    content: '';\n    display: block;\n    width: inherit;\n    height: 2rem;\n    background-color: ", ";\n    position: absolute;\n    top: calc(4rem + 0.5px);\n    pointer-events: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
}, function (_a) {
    var theme = _a.theme;
    return theme.white;
}, PALETTE.OPACITY_BLACK[50]);
export var OptionContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  height: inherit;\n  overflow-y: auto;\n  text-align: center;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  flex: 1;\n  height: inherit;\n  overflow-y: auto;\n  text-align: center;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"])));
export var Option = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  color: ", ";\n\n  &:first-child {\n    margin-top: 4rem;\n  }\n\n  &:last-child {\n    margin-bottom: 4rem;\n  }\n"], ["\n  width: 100%;\n  height: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  color: ", ";\n\n  &:first-child {\n    margin-top: 4rem;\n  }\n\n  &:last-child {\n    margin-bottom: 4rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
});
export var OptionText = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: inherit;\n  height: inherit;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: inherit;\n  height: inherit;\n"])));
export var Radio = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  -webkit-border-radius: 0;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  opacity: 0;\n  width: 0;\n  height: 0;\n\n  &:checked + ", " {\n    color: ", ";\n    font-weight: 600;\n  }\n\n  &:focus + ", " {\n    box-shadow: inset 0 -0.125rem ", ";\n  }\n"], ["\n  -webkit-border-radius: 0;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  opacity: 0;\n  width: 0;\n  height: 0;\n\n  &:checked + ", " {\n    color: ", ";\n    font-weight: 600;\n  }\n\n  &:focus + ", " {\n    box-shadow: inset 0 -0.125rem ", ";\n  }\n"])), OptionText, function (_a) {
    var theme = _a.theme;
    return theme.gray[600];
}, OptionText, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
