var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createPortal } from 'react-dom';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import * as Styled from './Modal.styles';
var modalRoot = document.getElementById('modal');
var Modal = function (_a) {
    var open = _a.open, isClosableDimmer = _a.isClosableDimmer, showCloseButton = _a.showCloseButton, onClose = _a.onClose, children = _a.children;
    if (modalRoot === null) {
        // Note: 테스트(Jest)에서 modalRoot를 인식하지 못하는 문제해결
        modalRoot = document.createElement('div');
        modalRoot.setAttribute('id', 'modal');
        document.body.appendChild(modalRoot);
    }
    var handleMouseDownOverlay = function (_a) {
        var target = _a.target, currentTarget = _a.currentTarget;
        if (isClosableDimmer && target === currentTarget) {
            onClose();
        }
    };
    return createPortal(_jsx(Styled.Overlay, __assign({ open: open, onMouseDown: handleMouseDownOverlay }, { children: _jsxs(Styled.Modal, { children: [open && showCloseButton && (_jsx(Styled.CloseButton, __assign({ onClick: onClose }, { children: _jsx(CloseIcon, {}, void 0) }), void 0)), open && children] }, void 0) }), void 0), modalRoot);
};
Modal.Inner = Styled.Inner;
Modal.Header = Styled.Header;
Modal.Content = Styled.Content;
export default Modal;
