var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Styled from './TimePicker.styles';
import TimePickerOptions from './TimePickerOptions';
var TimePicker = function (_a) {
    var label = _a.label, _b = _a.step, step = _b === void 0 ? 1 : _b, range = _a.range, selectedTime = _a.selectedTime, onClick = _a.onClick, onChange = _a.onChange, onCloseOptions = _a.onCloseOptions;
    var getTimeText = function (key) {
        var time = range[key];
        if (time === null) {
            return key === 'start' ? '시작시간' : '종료시간';
        }
        return time.midday + " " + String(time.hour).padStart(2, '0') + " :\n    " + String(time.minute).padStart(2, '0');
    };
    return (_jsxs(Styled.Container, { children: [_jsxs(Styled.TimeContainer, __assign({ labelText: label, isOptionOpen: selectedTime !== null }, { children: [_jsx(Styled.TimeButton, __assign({ type: "button", "aria-label": "\uC2DC\uC791\uC2DC\uAC04", name: "start", onClick: onClick, selected: selectedTime === 'start' }, { children: getTimeText('start') }), void 0), _jsx("span", { children: "~" }, void 0), _jsx(Styled.TimeButton, __assign({ type: "button", "aria-label": "\uC885\uB8CC\uC2DC\uAC04", name: "end", onClick: onClick, selected: selectedTime === 'end' }, { children: getTimeText('end') }), void 0)] }), void 0), selectedTime !== null && (_jsx(Styled.OptionsContainer, { children: _jsx(TimePickerOptions, { time: range[selectedTime], step: step, onChange: onChange }, void 0) }, void 0)), selectedTime !== null && _jsx(Styled.Dimmer, { onClick: onCloseOptions }, void 0)] }, void 0));
};
export default TimePicker;
