var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var statusCSS = {
    success: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.green[500];
    }),
    error: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.red[500];
    }),
    default: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.gray[500];
    }),
};
export var Label = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  position: relative;\n  margin-top: ", ";\n  margin-bottom: ", ";\n"], ["\n  display: block;\n  position: relative;\n  margin-top: ", ";\n  margin-bottom: ", ";\n"])), function (_a) {
    var hasLabel = _a.hasLabel;
    return (hasLabel ? '0.5rem' : '0');
}, function (_a) {
    var hasMessage = _a.hasMessage;
    return (hasMessage ? '1.625rem' : '0');
});
export var LabelText = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  display: inline-block;\n  top: -0.375rem;\n  left: 0.75rem;\n  padding: 0 0.25rem;\n  font-size: 0.75rem;\n  background-color: white;\n  color: ", ";\n"], ["\n  position: absolute;\n  display: inline-block;\n  top: -0.375rem;\n  left: 0.75rem;\n  padding: 0 0.25rem;\n  font-size: 0.75rem;\n  background-color: white;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
});
export var Icon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n  width: 3rem;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  padding: 0.75rem 0.5rem;\n"], ["\n  position: absolute;\n  display: flex;\n  width: 3rem;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  padding: 0.75rem 0.5rem;\n"])));
export var Input = styled.input(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 0.75rem;\n  width: 100%;\n  font-size: 1rem;\n  border: 1px solid ", ";\n  border-radius: 0.125rem;\n  background: none;\n  outline: none;\n  ", ";\n\n  &:focus {\n    border-color: ", ";\n    box-shadow: inset 0px 0px 0px 1px ", ";\n  }\n\n  &:disabled {\n    -webkit-text-fill-color: ", ";\n    color: ", ";\n    opacity: 1;\n  }\n"], ["\n  padding: 0.75rem;\n  width: 100%;\n  font-size: 1rem;\n  border: 1px solid ", ";\n  border-radius: 0.125rem;\n  background: none;\n  outline: none;\n  ", ";\n\n  &:focus {\n    border-color: ", ";\n    box-shadow: inset 0px 0px 0px 1px ", ";\n  }\n\n  &:disabled {\n    -webkit-text-fill-color: ", ";\n    color: ", ";\n    opacity: 1;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
}, function (_a) {
    var icon = _a.icon;
    return (icon ? 'padding-left: 3rem' : '');
}, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
});
export var Message = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", ";\n  font-size: 0.75rem;\n  position: absolute;\n  margin: 0.25rem 0.5rem;\n"], ["\n  ", ";\n  font-size: 0.75rem;\n  position: absolute;\n  margin: 0.25rem 0.5rem;\n"])), function (_a) {
    var _b = _a.status, status = _b === void 0 ? 'default' : _b;
    return statusCSS[status];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
