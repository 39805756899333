var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FORM_MAX_WIDTH } from 'constants/style';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: ", ";\n  margin: 0 auto;\n"], ["\n  width: 100%;\n  max-width: ", ";\n  margin: 0 auto;\n"])), FORM_MAX_WIDTH);
export var PageTitle = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.5rem;\n  font-weight: 400;\n  text-align: center;\n  margin: 2.125rem auto;\n"], ["\n  font-size: 1.5rem;\n  font-weight: 400;\n  text-align: center;\n  margin: 2.125rem auto;\n"])));
export var PasswordChangeLinkMessage = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 1rem 0;\n  text-align: center;\n  font-size: 0.75rem;\n  color: ", ";\n\n  a {\n    color: ", ";\n    text-decoration: none;\n    margin-left: 0.375rem;\n\n    &:hover {\n      font-weight: 700;\n    }\n  }\n"], ["\n  margin: 1rem 0;\n  text-align: center;\n  font-size: 0.75rem;\n  color: ", ";\n\n  a {\n    color: ", ";\n    text-decoration: none;\n    margin-left: 0.375rem;\n\n    &:hover {\n      font-weight: 700;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
}, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
});
var templateObject_1, templateObject_2, templateObject_3;
