var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { theme } from 'App.styles';
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg';
import IconButton from 'components/IconButton/IconButton';
import MESSAGE from 'constants/message';
var ShareLinkButton = function (_a) {
    var map = _a.map, props = __rest(_a, ["map"]);
    var handleClick = function () {
        var _a;
        navigator.clipboard
            .writeText(window.location.origin + "/guest/" + ((_a = map.sharingMapId) !== null && _a !== void 0 ? _a : ''))
            .then(function () {
            alert(MESSAGE.MANAGER_MAIN.COPIED_SHARE_LINK);
        })
            .catch(function () {
            alert(MESSAGE.MANAGER_MAIN.UNEXPECTED_COPY_SHARE_LINK);
        });
    };
    return (_jsx(IconButton, __assign({}, props, { children: _jsx(LinkIcon, { width: "100%", height: "100%", fill: theme.primary[500], onClick: handleClick }, void 0) }), void 0));
};
export default ShareLinkButton;
