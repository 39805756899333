var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Z_INDEX } from 'constants/style';
export var Container = styled.aside(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 30%;\n  padding: 1.5rem 1rem;\n  min-width: ", "px;\n  box-shadow: 0.25rem 0 0.25rem 0 ", ";\n  z-index: ", ";\n\n  @media (max-width: ", "px) {\n    width: 100%;\n    min-width: 0;\n  }\n"], ["\n  width: 30%;\n  padding: 1.5rem 1rem;\n  min-width: ", "px;\n  box-shadow: 0.25rem 0 0.25rem 0 ", ";\n  z-index: ", ";\n\n  @media (max-width: ", "px) {\n    width: 100%;\n    min-width: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.sm;
}, function (_a) {
    var theme = _a.theme;
    return theme.shadow;
}, Z_INDEX.ASIDE, function (_a) {
    var breakpoints = _a.theme.breakpoints;
    return breakpoints.sm;
});
export var Notice = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  border-radius: 0.5rem;\n  padding: 0.5rem 1rem;\n"], ["\n  border: 1px solid ", ";\n  border-radius: 0.5rem;\n  padding: 0.5rem 1rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[300];
});
export var NoticeTitle = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: bold;\n  display: inline-block;\n  line-height: 1.25rem;\n  margin-bottom: 0.5rem;\n"], ["\n  color: ", ";\n  font-weight: bold;\n  display: inline-block;\n  line-height: 1.25rem;\n  margin-bottom: 0.5rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.primary[500];
});
export var NoticeText = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  color: ", ";\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n"], ["\n  flex: 1;\n  color: ", ";\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
});
export var SwitchContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 2rem 0;\n  text-align: center;\n"], ["\n  margin: 2rem 0;\n  text-align: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
