var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { postNotice } from 'api/managerMap';
import { ReactComponent as NoticeIcon } from 'assets/svg/notice.svg';
import Button from 'components/Button/Button';
import IconButton from 'components/IconButton/IconButton';
import Modal from 'components/Modal/Modal';
import TextArea from 'components/TextArea/TextArea';
import MESSAGE from 'constants/message';
import useInput from 'hooks/useInput';
import * as Styled from './ManagerIconButton.styled';
var MapNoticeButton = function (_a) {
    var map = _a.map, props = __rest(_a, ["map"]);
    var _b = useState(false), isModalOpened = _b[0], setIsModalOpened = _b[1];
    var _c = useInput(), noticeText = _c[0], onChangeNoticeText = _c[1], setNoticeText = _c[2];
    var handleModalClose = function () {
        setIsModalOpened(false);
    };
    var setMapNotice = useMutation(postNotice, {
        onSuccess: function () {
            alert(MESSAGE.MANAGER_MAIN.NOTICE_SET_SUCCESS);
            setIsModalOpened(false);
        },
        onError: function (error) {
            var _a, _b;
            alert((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message) !== null && _b !== void 0 ? _b : MESSAGE.MANAGER_MAIN.UNEXPECTED_NOTICE_SET_ERROR);
        },
    });
    var handleSubmitNotice = function (event) {
        event.preventDefault();
        setMapNotice.mutate({
            mapId: map.mapId,
            notice: noticeText,
        });
    };
    useEffect(function () {
        var _a;
        setNoticeText((_a = map.notice) !== null && _a !== void 0 ? _a : '');
    }, [setNoticeText, map]);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, __assign({}, props, { onClick: function () { return setIsModalOpened(true); } }, { children: _jsx(NoticeIcon, { width: "100%", height: "100%" }, void 0) }), void 0), isModalOpened && (_jsxs(Modal, __assign({ open: isModalOpened, isClosableDimmer: true, onClose: handleModalClose }, { children: [_jsx(Modal.Header, { children: "\uACF5\uC9C0\uC0AC\uD56D\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694" }, void 0), _jsx(Modal.Inner, { children: _jsxs("form", __assign({ onSubmit: handleSubmitNotice }, { children: [_jsx(TextArea, { label: "\uACF5\uC9C0\uC0AC\uD56D", rows: 4, maxLength: 100, value: noticeText, onChange: onChangeNoticeText, autoFocus: true }, void 0), _jsxs(Styled.ModalFooter, { children: [_jsx(Button, __assign({ variant: "text", type: "button", onClick: handleModalClose }, { children: "\uCDE8\uC18C" }), void 0), _jsx(Button, __assign({ variant: "text" }, { children: "\uD655\uC778" }), void 0)] }, void 0)] }), void 0) }, void 0)] }), void 0))] }, void 0));
};
export default MapNoticeButton;
