var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var EmojiSelector = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  padding: 0.75rem;\n  margin-top: 0.5rem;\n  margin-bottom: 2rem;\n  width: 100%;\n  border-top: 1px solid ", ";\n  background: none;\n  outline: none;\n  display: flex;\n  justify-content: center;\n"], ["\n  position: relative;\n  padding: 0.75rem;\n  margin-top: 0.5rem;\n  margin-bottom: 2rem;\n  width: 100%;\n  border-top: 1px solid ", ";\n  background: none;\n  outline: none;\n  display: flex;\n  justify-content: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
});
export var LabelText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  display: inline-block;\n  top: -0.375rem;\n  left: 50%;\n  transform: translateX(-50%);\n  padding: 0 0.25rem;\n  font-size: 0.75rem;\n  background-color: white;\n  color: ", ";\n"], ["\n  position: absolute;\n  display: inline-block;\n  top: -0.375rem;\n  left: 50%;\n  transform: translateX(-50%);\n  padding: 0 0.25rem;\n  font-size: 0.75rem;\n  background-color: white;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
});
export var EmojiList = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 1rem;\n  font-size: 2.5rem;\n  display: grid;\n  grid-template-rows: repeat(2, 4rem);\n  grid-template-columns: repeat(5, 4rem);\n  gap: 1.25rem;\n\n  @media (max-width: ", "px) {\n    font-size: 1.5rem;\n    grid-template-rows: repeat(2, 3rem);\n    grid-template-columns: repeat(5, 3rem);\n    gap: 0.5rem;\n  }\n"], ["\n  margin-top: 1rem;\n  font-size: 2.5rem;\n  display: grid;\n  grid-template-rows: repeat(2, 4rem);\n  grid-template-columns: repeat(5, 4rem);\n  gap: 1.25rem;\n\n  @media (max-width: ", "px) {\n    font-size: 1.5rem;\n    grid-template-rows: repeat(2, 3rem);\n    grid-template-columns: repeat(5, 3rem);\n    gap: 0.5rem;\n  }\n"])), function (_a) {
    var breakpoints = _a.theme.breakpoints;
    return breakpoints.sm;
});
export var EmojiItem = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  margin-bottom: 0;\n  justify-self: center;\n  align-self: center;\n"], ["\n  position: relative;\n  margin-bottom: 0;\n  justify-self: center;\n  align-self: center;\n"])));
export var EmojiCode = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n  border-radius: 999px;\n  width: 4rem;\n  height: 4rem;\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n\n  input:checked + & {\n    background-color: ", ";\n  }\n\n  @media (max-width: ", "px) {\n    width: 3rem;\n    height: 3rem;\n  }\n"], ["\n  cursor: pointer;\n  border-radius: 999px;\n  width: 4rem;\n  height: 4rem;\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n\n  input:checked + & {\n    background-color: ", ";\n  }\n\n  @media (max-width: ", "px) {\n    width: 3rem;\n    height: 3rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[100];
}, function (_a) {
    var theme = _a.theme;
    return theme.primary[400];
}, function (_a) {
    var breakpoints = _a.theme.breakpoints;
    return breakpoints.sm;
});
export var Radio = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 0;\n  height: 0;\n  visibility: hidden;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 0;\n  height: 0;\n  visibility: hidden;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
