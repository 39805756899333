var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from 'react-query';
import { queryEmojiList } from 'api/join';
var useEmojiList = function (options) {
    return useQuery(['getEmojiList'], queryEmojiList, __assign(__assign({}, options), { refetchOnWindowFocus: false }));
};
export default useEmojiList;
