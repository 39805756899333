var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
var useInputs = function (initialValues) {
    var _a = useState(initialValues), values = _a[0], setValues = _a[1];
    var getValue = function (event) {
        if (event.target.type === 'checkbox') {
            return event.target.checked;
        }
        return event.target.value;
    };
    var handleChange = function (event) {
        setValues(function (prevValues) {
            var _a;
            return (__assign(__assign({}, prevValues), (_a = {}, _a[event.target.name] = getValue(event), _a)));
        });
    };
    return [values, handleChange, setValues];
};
export default useInputs;
