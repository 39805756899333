var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { ReactComponent as CaretDownIcon } from 'assets/svg/caret-down.svg';
import PALETTE from 'constants/palette';
import * as Styled from './Select.styles';
var Select = function (_a) {
    var name = _a.name, label = _a.label, options = _a.options, maxheight = _a.maxheight, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.value, value = _c === void 0 ? '' : _c, _d = _a.onChange, onChange = _d === void 0 ? function () { return null; } : _d;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var selectedOption = options.find(function (option) { return option.value === value; });
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var selectOption = function (selectedValue) {
        setOpen(false);
        onChange(selectedValue);
    };
    useEffect(function () {
        if (options.length === 0)
            setOpen(false);
    }, [options.length]);
    return (_jsxs(Styled.Select, { children: [_jsx(Styled.Label, __assign({ id: name + "-label" }, { children: label }), void 0), _jsxs(Styled.ListBoxButton, __assign({ type: "button", id: name + "-button", "aria-haspopup": "listbox", "aria-labelledby": name + "-label " + name + "-button", "aria-expanded": open, disabled: options.length === 0 || disabled, onClick: handleToggle }, { children: [_jsxs(Styled.OptionChildrenWrapper, { children: [value === '' && _jsx(Styled.ListBoxLabel, { children: label }, void 0), (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.title) ? selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.title : selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.children] }, void 0), _jsx(Styled.CaretDownIconWrapper, __assign({ open: open }, { children: _jsx(CaretDownIcon, { fill: PALETTE.GRAY[400] }, void 0) }), void 0)] }), void 0), open && (_jsx(Styled.ListBox, __assign({ tabIndex: 0, role: "listbox", "aria-activedescendant": "" + value, "aria-labelledby": name + "-label", maxheight: maxheight }, { children: options.map(function (option) { return (_jsx(Styled.Option, __assign({ role: "option", value: option.value, "aria-selected": option.value === value, onClick: function () { return selectOption(option.value); } }, { children: option.children }), option.value)); }) }), void 0))] }, void 0));
};
export default Select;
