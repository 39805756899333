var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Modal from 'components/Modal/Modal';
import useInput from 'hooks/useInput';
import * as Styled from './PasswordInputModal.styles';
var PasswordInputModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, onSubmit = _a.onSubmit;
    var _b = useInput(''), passwordInput = _b[0], onChangePasswordInput = _b[1];
    var handleSubmit = function (event) {
        event.preventDefault();
        onSubmit(passwordInput);
    };
    return (_jsxs(Modal, __assign({ open: open, isClosableDimmer: true, onClose: onClose }, { children: [_jsx(Modal.Header, { children: "\uC608\uC57D\uC2DC \uC0AC\uC6A9\uD558\uC2E0 \uBE44\uBC00\uBC88\uD638\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694." }, void 0), _jsx(Modal.Inner, { children: _jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsx(Input, { type: "password", label: "\uBE44\uBC00\uBC88\uD638", minLength: 4, maxLength: 4, value: passwordInput, onChange: onChangePasswordInput }, void 0), _jsxs(Styled.DeleteModalContainer, { children: [_jsx(Button, __assign({ variant: "text", type: "button", onClick: onClose }, { children: "\uCDE8\uC18C" }), void 0), _jsx(Button, __assign({ variant: "text", type: "submit" }, { children: "\uD655\uC778" }), void 0)] }, void 0)] }), void 0) }, void 0)] }), void 0));
};
export default PasswordInputModal;
