var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { formatTime } from 'utils/datetime';
import * as Styled from './ManagerReservationListItem.styles';
var ManagerReservationListItem = function (_a) {
    var reservation = _a.reservation, control = _a.control, status = _a.status, props = __rest(_a, ["reservation", "control", "status"]);
    var name = reservation.name, description = reservation.description, startDateTime = reservation.startDateTime, endDateTime = reservation.endDateTime;
    var start = formatTime(dayjs(startDateTime).tz());
    var end = formatTime(dayjs(endDateTime).tz());
    return (_jsxs(Styled.Item, __assign({ role: "listitem" }, props, { children: [_jsxs(Styled.InfoWrapper, __assign({ status: status }, { children: [status && _jsx(Styled.StatusBadge, __assign({ status: status }, { children: status }), void 0), _jsxs(Styled.Info, { children: [_jsx(Styled.Name, { children: _jsx("span", { children: name }, void 0) }, void 0), _jsx(Styled.Description, __assign({ status: status }, { children: description }), void 0), _jsx(Styled.Time, { children: _jsxs("span", { children: [start, " - ", end] }, void 0) }, void 0)] }, void 0)] }), void 0), control && _jsx(Styled.Control, { children: control }, void 0)] }), void 0));
};
export default ManagerReservationListItem;
