var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import Button from 'components/Button/Button';
import { Z_INDEX } from 'constants/style';
export var ReservationForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 1.5rem 0 0;\n"], ["\n  margin: 1.5rem 0 0;\n"])));
export var Section = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 1.5rem 0;\n"], ["\n  margin: 1.5rem 0;\n"])));
export var InputWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 1.625rem;\n\n  & > label,\n  & > div {\n    flex: 1;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 1.625rem;\n\n  & > label,\n  & > div {\n    flex: 1;\n  }\n"])));
export var InputsRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  gap: 0.75rem;\n\n  & > label,\n  & > div {\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  gap: 0.75rem;\n\n  & > label,\n  & > div {\n    flex: 1;\n  }\n"])));
export var TimeFormMessageWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  gap: 0.25rem;\n  margin-top: 0.5rem;\n"], ["\n  display: flex;\n  gap: 0.25rem;\n  margin-top: 0.5rem;\n"])));
export var TimeFormMessageList = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n"])));
export var TimeFormMessage = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  left: 0.75rem;\n  bottom: -1rem;\n  font-size: 0.75rem;\n  height: 1em;\n  color: ", ";\n  ", ";\n"], ["\n  left: 0.75rem;\n  bottom: -1rem;\n  font-size: 0.75rem;\n  height: 1em;\n  color: ", ";\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[500];
}, function (_a) {
    var fontWeight = _a.fontWeight;
    return fontWeight && "font-weight: " + fontWeight;
});
export var ButtonWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  z-index: ", ";\n"], ["\n  width: 100%;\n  z-index: ", ";\n"])), Z_INDEX.RESERVATION_BUTTON);
export var ReservationButton = styled(Button)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  &:disabled {\n    background-color: ", ";\n    color: ", ";\n    cursor: not-allowed;\n  }\n"], ["\n  &:disabled {\n    background-color: ", ";\n    color: ", ";\n    cursor: not-allowed;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.gray[300];
});
export var SpaceOption = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 0.75rem;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 0.75rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
