var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { queryMemberReservations } from '../../api/guestReservation';
var useMemberReservations = function () {
    var infiniteQueryResponse = useInfiniteQuery(['infiniteQueryMemberRreservations'], function (_a) {
        var _b = _a.pageParam, pageParam = _b === void 0 ? 0 : _b;
        return queryMemberReservations({ page: Number(pageParam) });
    }, {
        getNextPageParam: function (response) { return response.data.hasNext; },
    });
    var flattedResults = useMemo(function () { var _a, _b; return (_b = (_a = infiniteQueryResponse.data) === null || _a === void 0 ? void 0 : _a.pages.flatMap(function (result) { return result.data.data; })) !== null && _b !== void 0 ? _b : []; }, [infiniteQueryResponse.data]);
    return __assign(__assign({}, infiniteQueryResponse), { flattedResults: flattedResults });
};
export default useMemberReservations;
