var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n"], ["\n  display: inline-block;\n"])));
export var ButtonContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0.375rem 0.75rem;\n  display: flex;\n  border-radius: 3rem;\n  background-color: ", ";\n"], ["\n  padding: 0.375rem 0.75rem;\n  display: flex;\n  border-radius: 3rem;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[100];
});
export var SwitchButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0.5rem 1.25rem;\n  margin-right: 0.5rem;\n  border: 0;\n  border-radius: 3rem;\n  font-size: 1rem;\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n\n  &:last-of-type {\n    margin-right: 0;\n  }\n"], ["\n  padding: 0.5rem 1.25rem;\n  margin-right: 0.5rem;\n  border: 0;\n  border-radius: 3rem;\n  font-size: 1rem;\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n\n  &:last-of-type {\n    margin-right: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme, selected = _a.selected;
    return (selected ? theme.primary[500] : theme.gray[100]);
}, function (_a) {
    var theme = _a.theme, selected = _a.selected;
    return (selected ? theme.white : theme.black[500]);
});
var templateObject_1, templateObject_2, templateObject_3;
