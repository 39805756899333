var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme } from 'App.styles';
import { ReactComponent as CaretIcon } from 'assets/svg/caret-right.svg';
import * as Styled from './MapListItem.styles';
var MapListItem = function (_a) {
    var map = _a.map, control = _a.control, _b = _a.onClick, onClick = _b === void 0 ? function () { return undefined; } : _b;
    return (_jsxs(Styled.Container, __assign({ role: "listitem" }, { children: [_jsxs(Styled.MapInfo, __assign({ onClick: onClick }, { children: [_jsx(Styled.ImageWrapper, { children: _jsx(Styled.ImageInner, { dangerouslySetInnerHTML: { __html: "" + map.thumbnail } }, void 0) }, void 0), _jsxs(Styled.TitleWrapper, { children: [_jsx(Styled.Title, { children: map.mapName }, void 0), _jsx(CaretIcon, { width: 36, height: 36, fill: theme.gray[400] }, void 0)] }, void 0)] }), void 0), control && _jsx(Styled.ControlWrapper, { children: control }, void 0)] }), void 0));
};
export default MapListItem;
