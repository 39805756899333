import { QS } from '@toss/utils';
import api from './api';
export var queryGuestSpaces = function (_a) {
    var queryKey = _a.queryKey;
    var data = queryKey[1];
    var mapId = data.mapId;
    return api.get("/guests/maps/" + mapId + "/spaces");
};
export var queryGuestSpace = function (_a) {
    var queryKey = _a.queryKey;
    var data = queryKey[1];
    var mapId = data.mapId, spaceId = data.spaceId;
    return api.get("/guests/maps/" + mapId + "/spaces/" + spaceId);
};
export var queryGuestSpaceAvailable = function (_a) {
    var mapId = _a.mapId, startDateTime = _a.startDateTime, endDateTime = _a.endDateTime;
    return api.get("/guests/maps/" + mapId + "/spaces/availability" + QS.create({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
    }));
};
