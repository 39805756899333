export var Order;
(function (Order) {
    Order["Ascending"] = "ascending";
    Order["Descending"] = "descending";
})(Order || (Order = {}));
export var ReservationStatus;
(function (ReservationStatus) {
    ReservationStatus["using"] = "\uC0AC\uC6A9 \uC911";
    ReservationStatus["done"] = "\uC0AC\uC6A9 \uC644\uB8CC";
})(ReservationStatus || (ReservationStatus = {}));
