var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { deleteMap } from 'api/managerMap';
import { ReactComponent as DeleteIcon } from 'assets/svg/delete.svg';
import Header from 'components/Header/Header';
import IconButton from 'components/IconButton/IconButton';
import MapNoticeButton from 'components/ManagerIconButtons/MapNoticeButton';
import ShareLinkButton from 'components/ManagerIconButtons/ShareLinkButton';
import SlackNotiButton from 'components/ManagerIconButtons/SlackNotiButton';
import MapListItem from 'components/MapListItem/MapListItem';
import MemberInfo from 'components/MemberInfo/MemberInfo';
import TabLayout from 'components/TabLayout/TabLayout';
import MESSAGE from 'constants/message';
import PATH, { HREF } from 'constants/path';
import { TAB_LABEL, TAB_LIST, TAB_PATH_FOR_LABEL } from 'constants/tab';
import useManagerMaps from 'hooks/query/useManagerMaps';
import * as Styled from './ManagerMapList.styles';
var ManagerMapList = function () {
    var history = useHistory();
    var maps = useManagerMaps({
        onError: function (error) {
            var _a, _b;
            alert((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message) !== null && _b !== void 0 ? _b : MESSAGE.MANAGER_MAIN.UNEXPECTED_GET_DATA_ERROR);
        },
    }).data;
    var removeMap = useMutation(deleteMap, {
        onSuccess: function () {
            alert(MESSAGE.MANAGER_MAIN.MAP_DELETED);
        },
        onError: function (error) {
            var _a, _b;
            alert((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message) !== null && _b !== void 0 ? _b : MESSAGE.MANAGER_MAIN.UNEXPECTED_MAP_DELETE_ERROR);
        },
    });
    var handleMapRemove = function (mapId) {
        removeMap.mutate({ mapId: mapId });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}, void 0), _jsxs(TabLayout, __assign({ tabList: TAB_LIST, defaultTabLabel: TAB_LABEL.MANAGER, onClick: function (selectedTab) { return history.push(TAB_PATH_FOR_LABEL[selectedTab]); } }, { children: [_jsx(MemberInfo, {}, void 0), _jsxs(Styled.MapListContainer, { children: [_jsx(Styled.MapListTitle, { children: "\uB098\uC758 \uB9F5" }, void 0), _jsx(Styled.MapList, __assign({ role: "list" }, { children: maps === null || maps === void 0 ? void 0 : maps.data.maps.map(function (map) { return (_jsx(MapListItem, { map: map, onClick: function () { return history.push(HREF.MANAGER_MAP_DETAIL(map.mapId)); }, control: _jsxs(_Fragment, { children: [_jsx(ShareLinkButton, { map: map }, void 0), _jsx(MapNoticeButton, { map: map }, void 0), _jsx(SlackNotiButton, { map: map }, void 0), _jsx(IconButton, __assign({ onClick: function () { return handleMapRemove(map.mapId); } }, { children: _jsx(DeleteIcon, { width: "24", height: "24" }, void 0) }), void 0)] }, void 0) }, map.mapId)); }) }), void 0), _jsx(Styled.ButtonContainer, { children: _jsx(Styled.RoundedButton, __assign({ variant: "default", shape: "round", onClick: function () { return history.push(PATH.MANAGER_MAP_CREATE); } }, { children: "\uB9F5 \uB9CC\uB4E4\uAE30" }), void 0) }, void 0)] }, void 0)] }), void 0)] }, void 0));
};
export default ManagerMapList;
