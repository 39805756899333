var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0.875rem;\n  border-bottom: 1px solid ", ";\n\n  :last-of-type {\n    border-bottom: 0;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0.875rem;\n  border-bottom: 1px solid ", ";\n\n  :last-of-type {\n    border-bottom: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.gray[300];
});
export var MapInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  flex: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  flex: 1;\n"])));
export var ImageWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 120px;\n  height: 120px;\n  margin-right: 36px;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 120px;\n  height: 120px;\n  margin-right: 36px;\n"])));
export var ImageInner = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n\n  svg {\n    width: 100% !important;\n    height: 100% !important;\n  }\n"], ["\n  width: 100%;\n\n  svg {\n    width: 100% !important;\n    height: 100% !important;\n  }\n"])));
export var TitleWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var Title = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 1.125rem;\n  margin-right: 0.5rem;\n"], ["\n  font-size: 1.125rem;\n  margin-right: 0.5rem;\n"])));
export var ControlWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  & > * {\n    margin-right: 1.25rem;\n\n    :last-of-type {\n      margin-right: 0;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  & > * {\n    margin-right: 1.25rem;\n\n    :last-of-type {\n      margin-right: 0;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
