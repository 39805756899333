var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MESSAGE from 'constants/message';
import PATH from 'constants/path';
import useGuestMap from 'hooks/query/useGuestMap';
import GuestMap from './GuestMap';
import * as Styled from './GuestMap.styles';
import GuestMapFormProvider from './providers/GuestMapFormProvider';
var GuestMapContainer = function () {
    var sharingMapId = useParams().sharingMapId;
    var history = useHistory();
    var _a = useState(null), map = _a[0], setMap = _a[1];
    useGuestMap({ sharingMapId: sharingMapId }, {
        onError: function () {
            history.replace(PATH.NOT_FOUND);
        },
        onSuccess: function (response) {
            var mapData = response.data;
            try {
                setMap(__assign(__assign({}, mapData), { mapDrawing: JSON.parse(mapData.mapDrawing) }));
            }
            catch (error) {
                alert(MESSAGE.GUEST_MAP.MAP_DRAWING_PARSE_ERROR);
            }
        },
        retry: false,
    });
    if (map === null)
        return _jsx(_Fragment, {}, void 0);
    return (_jsx(Styled.Page, { children: _jsx(GuestMapFormProvider, __assign({ mapId: map.mapId }, { children: _jsx(GuestMap, { map: map }, void 0) }), void 0) }, void 0));
};
export default GuestMapContainer;
