var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var colorDotSizeCSS = {
    medium: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 1rem;\n    height: 1rem;\n  "], ["\n    width: 1rem;\n    height: 1rem;\n  "]))),
    large: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 1.5rem;\n    height: 1.5rem;\n  "], ["\n    width: 1.5rem;\n    height: 1.5rem;\n  "]))),
};
var ColorDot = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  background-color: ", ";\n  border-radius: 50%;\n  ", ";\n"], ["\n  display: inline-block;\n  background-color: ", ";\n  border-radius: 50%;\n  ", ";\n"])), function (_a) {
    var color = _a.color;
    return color;
}, function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'medium' : _b;
    return colorDotSizeCSS[size];
});
export default ColorDot;
var templateObject_1, templateObject_2, templateObject_3;
